import { Img, Button, Heading, Input, TextArea } from "../../components/HomeCom";
import React from "react";

export default function ContactFormSection() {
  return (
    <>
      {/* contact form section */}
      <div className="mt-[368px] lg:mt-[150px] flex flex-col items-center">
        <div className="container-xs flex flex-col gap-[70px]  lg:gap-[70px] lg:px-5 md:gap-[52px] md:px-5 sm:gap-[35px]">
          <div className="flex gap-10 rounded-[20px]   border border-solid border-white-a700_47 bg-deep_purple-a400_2d p-5 md:flex-col">
          <div className="stackview_border bg-blue_gray-900_01  relative h-[690px] w-[60%] content-end rounded-[20px] lg:h-auto md:h-auto md:w-full">
            <div className=" flex flex-1 flex-col gap-[30px] rounded-[20px] bg-gray-900_02 py-[58px] pl-[60px] pr-14 md:self-stretch md:p-5 sm:px-5 sm:py-4">
              <div className="flex gap-1.5">
                <Img src="Images/HomePag-img/directbox-notif.svg" alt="Thumbs Up Image" className="h-[22px] w-[22px]" />
                <a href="./#">
                  <Heading size="headingxs" as="p" className="!font-manrope !font-semibold uppercase">
                    Contact Us
                  </Heading>
                </a>
              </div>
              <Heading
                size="headingxl"
                as="h4"
                className="text-lg lg:text-2xl font-bold leading-7 lg:leading-9"
              >
                Questions about our sports betting tools?
              </Heading>
              <div className="flex flex-col gap-6">
                <div className="flex flex-row gap-4 md:flex-row">
                  <Input
                    shape="round"
                    name="Name Input"
                    placeholder="Your Name"
                    prefix={<Img src="Images/HomePag-img/user.svg" alt="User" className="h-4 w-4" />}
                    className="w-full"
                  />
                  <Input
                    shape="round"
                    type="email"
                    name="Email Input"
                    placeholder="team@updownleftright.io"
                    prefix={<Img src="Images/HomePag-img/sms.svg" alt="SMS" className="h-4 w-4" />}
                    className="w-full"
                  />
                </div>
                <Input
                  shape="round"
                  name="Phone Input"
                  placeholder="(555) 123-4567"
                  prefix={<Img src="Images/HomePag-img/call.svg" alt="Call" className="h-4 w-4" />}
                  className="w-full"
                />
                <TextArea
                  shape="round"
                  name="Message TextArea"
                  placeholder="Tell us more about what you're looking for..."
                  prefix={<Img src="/Images/HomePag-img/messages-2.png" alt="Call" className="h-4 w-4" />}
                  className="w-full border-white-a700_14 text-white-a700_01"
                />
                <Button
                  color="teal_A400"
                  size="3xl"
                  rightIcon={<Img src="Images/HomePag-img/Arrow 2.png" alt="Arrow" className="h-3 w-3" />}
                  className="mt-10 lg:mt-6 lg:mx-auto w-60  rounded-full font-bold"
                >
                  Send Message
                </Button>
              </div>
            </div>
            </div>

            
          <div className="stackview_border bg-blue_gray-900_01 relative h-[700px] w-[35%] mx-auto  rounded-[20px] lg:h-auto md:h-auto md:w-full">

               <div className="relative   bg-gray-900_02 rounded-2xl flex-1">
              <div className="relative p-6 lg:p-8">
              <Img
                src="./Images/Dashboard-img/search.png"
                alt="Dashboard Preview"
                className="w-[344px] h-[450px] mx-auto  md:w-[30%] lg:w-[80%] lg:top-[58%] "  // Ajusta la posición de la imagen
              />
                <Heading size="headingxl" as="h4" className="text-center font-bold">
                  Start winning with <br/> UpDownLeftRight.io
                </Heading>
                <Heading size="texts"  as="p" className="text-center text-indigo-100 mt-4 lg:mt-4">
                  Get instant access to arbitrage plays, <br/> +EV bets, and referral offers.
                </Heading>
                <div className="flex flex-row gap-4 mt-4 lg:mt-6 lg:flex-row lg:justify-center">
                  <Button
                    color="teal_A400"
                    className="w-full max-w-xs rounded-full font-bold"
                    onClick={() => (window.location.href = "/Signup")}
                  >
                    Start Free Trial
                  </Button>
                </div>
              </div>
            </div>
            </div>
          </div>

          <div className="flex flex-col gap-10 lg:gap-12">
            <div className="  flex sm:flex-col gap-6 lg:gap-8">
              <div className="flex flex-1 gap-4 items-center">
                <Button
                  color="blue_gray_900_01"
                  size="xl"
                  shape="circle"
                  className="w-12 h-12 lg:w-14 lg:h-14 rounded-full"
                >
                  <Img src="Images/HomePag-img/sms.svg" />
                </Button>
                <Heading size="text2xl" as="p" className="text-base lg:text-lg">
                  team@updownleftright.io
                </Heading>
              </div>
              <div className="flex flex-1 gap-4 items-center">
                <Button
                  color="blue_gray_900_01"
                  size="xl"
                  shape="circle"
                  className="w-12 h-12 lg:w-14 lg:h-14 rounded-full"
                >
                  <Img src="Images/HomePag-img/call.svg" />
                </Button>
                <Heading size="text2xl" as="p" className="text-base lg:text-lg">
                  +(1) 888-555-1234
                </Heading>
              </div>
              <div className="flex gap-4 items-center">
                <Button
                  color="blue_gray_900_01"
                  size="xl"
                  shape="circle"
                  className="w-12 h-12 lg:w-14 lg:h-14 rounded-full"
                >
                  <Img src="Images/HomePag-img/location.svg" />
                </Button>
                <Heading size="text2xl" as="p" className="text-base lg:text-lg">
                  Chicago, IL, USA
                </Heading>
              </div>
            </div>
            <div>
              <div className="flex flex-col gap-10">
              <div>
                <div>
                  <div className="h-px bg-blue_gray-900_02" />
                  <div className="flex items-center justify-center md:flex-col">
                    <Heading size="headingmd" as="h6" className="flex !font-semibold">
                      <span>Home &nbsp;</span>
                      <span>&nbsp; &nbsp;</span>
                      <a href="./#" className="inline underline">
                        Features
                      </a>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp;Pricing</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>&nbsp; &nbsp;</span>
                      <span>Blog &nbsp;</span>
                      <span>&nbsp;&nbsp;</span>
                      <span>Contact</span>
                    </Heading>
                    <div className="flex flex-1 items-center justify-end md:flex-col md:self-stretch">
  <div className="h-[120px] w-px bg-blue_gray-900_02 md:h-px md:w-[120px]" />
  <div className="relative h-[60px] w-[82%] lg:h-auto md:h-auto md:w-full">
    <div className="relative flex items-center w-full">
      <Input
        type="email"
        name="Email Input"
        placeholder="Enter your email"
        prefix={
          <Img
            src="Images/HomePag-img/sms.svg"
            alt="Settings"
            className="my-0.5 h-[18px] w-[18px]"
          />
        }
        className="w-full rounded-[30px] !text-indigo-100 pr-[150px]" // Added padding to the right for button space
      />
      <Button
        color="gray_900_02"
        size="xs"
        className="absolute right-0 top-0 bottom-0 my-auto rounded-[20px] font-bold capitalize"
      >
        Subscribe
      </Button>
    </div>
  </div>
</div>

                  </div>
                  <div className="h-px bg-blue_gray-900_02" />
                </div>
              </div>
              <div className="mr-1 flex items-center justify-center md:mr-0 md:flex-col">
                <Heading as="p" className="mb-1.5 self-end !font-normal !text-indigo-100 md:self-auto">
                  Copyright © 2024 UpDownLeftRight.io - Sports Betting Analytics. All rights reserved.
                </Heading>
                <div className="flex flex-1 justify-end gap-[11px] md:self-stretch">

                  <Button
                    color="blue_gray_900_01"
                    size="sm"
                    shape="circle"
                    className="w-[40px]  !rounded-[20px]"
                  >
                              <Img src="Images/HomePag-img/Facebook.png" alt="Facebook Icon"  />
                  </Button>
                  <Button
                    color="blue_gray_900_01"
                    size="sm"
                    shape="circle"
                    className="w-[40px] !rounded-[20px]"
                  >
                    <Img src="Images/HomePag-img/in.png" />
                  </Button>
                  <Button
                    color="blue_gray_900_01"
                    size="sm"
                    shape="circle"
                    className="w-[40px] !rounded-[20px]"
                  >
                  <Img src="Images/HomePag-img/Insta.png" alt="Info Icon" className=" h-[50px]" />
                  </Button>
                  <Button
                    color="blue_gray_900_01"
                    size="sm"
                    shape="circle"
                    className="w-[40px] !rounded-[20px]"
                  >
                              <Img src="Images/HomePag-img/Arrow.png" alt="Save Icon"  />
                  </Button>


                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}
