import React from "react";
import { useNavigate } from "react-router-dom";
interface Props {
  className?: string;
}

export default function Header({ className, ...props }: Props) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); 
  };

  return (
    <header {...props} className={`self-stretch bg-neutral-neutral_bg__day_ ${className}`}>
      <div className="mt-[18px] flex w-full flex-col items-start gap-3.5">
        <div className="flex flex-1 items-center justify-between gap-5 md:self-stretch sm:flex-col">
          <button  className="focus:outline-none">
            <img
              src={"./Images/LogoDark.png"}
              alt="Header Logo"
              className="h-[60px] w-[148px] object-contain cursor-pointer"
              onClick={handleGoBack}
            />
          </button>
        </div>
      </div>
      <div className="h-px w-[84%] self-end bg-neutral-neutral_border_day_" />
    </header>
  );
}
