import { Helmet } from "react-helmet";
import { Text, Button, Img, Input, Heading } from "../../../../components";
import Header from "../../../../components/Header";
//import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "components/auth/AuthProvider";
import { useLazyQuery } from "@apollo/client";
import { EDIT_SETTINGS_REQUEST } from "components/queries/allQueries";
import {
  bookOptions,
  leagueOptions,
  marketOptions,
} from "components/const/variables";
import { Select } from "antd";
import { options } from "components/const/variables";
import { toast } from "sonner";

const editProfileToast = "edit-profile-toast";

const { Option } = Select;

interface AntSelectBoxProps {
  options?: typeof leagueOptions;
  value?: options[];
  onChange: (options: options[]) => void;
  placeholder?: string;
}

const AntSelectBox: React.FC<AntSelectBoxProps> = ({ 
  options = leagueOptions, 
  value, 
  onChange,
  ...props 
}) => {
  return (
    <Select
      mode="multiple"
      maxTagCount={2}
      value={value?.map((opt: options) => opt.value) || []}
      onChange={(selectedValues: string[]) => {
        const selectedOptions = selectedValues.map(val => 
          options.find(opt => opt.value === val) || { value: val, label: val }
        );
        onChange(selectedOptions);
      }}
      {...props}
      style={{
        width: "100%",
        height: "40px",
        borderRadius: "8px",
      }}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default function EditProfile() {
  const {
    userEmail,
    userFirstName,
    userLastName,
    userPhone,
    userAvatar,
    tokens,
    userFilters,
  } = useAuth();
  //const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [phone, setPhone] = useState("");
  const [avatar, setAvatar] = useState("");

  /*** optimizer filters */
  const [leagueOption, setLeagueOption] = useState<options[]>([]);
  const [marketOption, setMarketOption] = useState<options[]>([]);
  const [oddsOption, setOddsOption] = useState<string>("");
  const [minProfitOption, setMinProfitOption] = useState<string>("");

  /*** arbitrage filters */
  const [arbLeagueOption, setArbLeagueOption] = useState<options[]>([]);
  const [arbMarketOption, setArbMarketOption] = useState<options[]>([]);
  const [arbBookOption, setArbBookOption] = useState<options[]>([]);

  /*** positive ev filters */
  const [positiveEvLeagueOption, setPositiveEvLeagueOption] = useState<
    options[]
  >([]);
  const [positiveEvMarketOption, setPositiveEvMarketOption] = useState<
    options[]
  >([]);
  const [positiveEvOddsOption, setPositiveEvOddsOption] = useState<string>("");

  const [sendEditProfileRequest, { loading, error, data }] = useLazyQuery(
    EDIT_SETTINGS_REQUEST,
  );

  useEffect(() => {
    console.log("userFilters:", userFilters);
    setEmail(userEmail);
    setFirst_name(userFirstName);
    setLast_name(userLastName);
    setPhone(userPhone);
    setAvatar(userAvatar);

    // Convert string arrays to option objects
    if (userFilters?.optimizer?.league) {
      const leagues = userFilters.optimizer.league.map(league => ({
        value: league,
        label: league
      }));
      console.log("Setting league options:", leagues);
      setLeagueOption(leagues);
    }

    if (userFilters?.optimizer?.market) {
      const markets = userFilters.optimizer.market.map(market => ({
        value: market,
        label: market
      }));
      console.log("Setting market options:", markets);
      setMarketOption(markets);
    }

    setOddsOption(userFilters?.optimizer?.odds || "");
    setMinProfitOption(userFilters?.arbitrage?.minimumProfit || "");

    if (userFilters?.arbitrage?.sports) {
      setArbLeagueOption(
        userFilters.arbitrage.sports.map(sport => ({
          value: sport,
          label: sport
        }))
      );
    }

    if (userFilters?.arbitrage?.markets) {
      setArbMarketOption(
        userFilters.arbitrage.markets.map(market => ({
          value: market,
          label: market
        }))
      );
    }

    if (userFilters?.arbitrage?.books) {
      setArbBookOption(
        userFilters.arbitrage.books.map(book => ({
          value: book,
          label: book
        }))
      );
    }

    if (userFilters?.positiveEv?.sports) {
      setPositiveEvLeagueOption(
        userFilters.positiveEv.sports.map(sport => ({
          value: sport,
          label: sport
        }))
      );
    }

    if (userFilters?.positiveEv?.markets) {
      setPositiveEvMarketOption(
        userFilters.positiveEv.markets.map(market => ({
          value: market,
          label: market
        }))
      );
    }

    setPositiveEvOddsOption(userFilters?.positiveEv?.odds || "");
  }, [userEmail, userFirstName, userLastName, userPhone, userAvatar, userFilters]);

  const handleSave = useCallback(() => {
    toast.loading("Updating profile...", { id: editProfileToast });
    
    // Debug log the current state
    console.log("Saving with options:", {
      leagueOption,
      marketOption,
      arbLeagueOption,
      arbMarketOption,
      arbBookOption,
      positiveEvLeagueOption,
      positiveEvMarketOption
    });

    sendEditProfileRequest({
      variables: {
        accessToken: tokens.accessToken,
        settings: {
          phone: phone,
          email: email,
          first_name: first_name,
          last_name: last_name,
          avatar: avatar,
          filters: {
            optimizer: {
              sports: leagueOption?.map((option) => option.label) || [],
              markets: marketOption?.map((option) => option.label) || [],
              odds: oddsOption,
            },
            arbitrage: {
              minimumProfit: minProfitOption,
              sports: arbLeagueOption?.map((option) => option.label) || [],
              markets: arbMarketOption?.map((option) => option.label) || [],
              books: arbBookOption?.map((option) => option.label) || [],
            },
            positiveEv: {
              sports: positiveEvLeagueOption?.map((option) => option.label) || [],
              markets: positiveEvMarketOption?.map((option) => option.label) || [],
              odds: positiveEvOddsOption,
            },
          },
        },
      },
    })
      .then((response) => {
        if (response && response.data && !response.data.error) {
          toast.success("Profile updated successfully!", {
            id: editProfileToast,
          });
        } else {
          toast.error(
            "Failed to update profile: " +
              (response?.data?.error || "Unknown error"),
            { id: editProfileToast },
          );
        }
      })
      .catch((err) => {
        toast.error("Error updating profile: " + err.message, {
          id: editProfileToast,
        });
        console.error("Profile update error:", err);
      });
  }, [phone, email, first_name, last_name, avatar, sendEditProfileRequest, tokens.accessToken, leagueOption, marketOption, oddsOption, minProfitOption, arbLeagueOption, arbMarketOption, arbBookOption, positiveEvLeagueOption, positiveEvMarketOption, positiveEvOddsOption]);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result as string);
        toast.success("Profile picture updated!");
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setAvatar("");
    toast.success("Profile picture removed!");
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error! {error.message}</p>;

  if (data && !data.error) {
    console.log("updated settings");
  }

  return (
    <>
      <Helmet>
        <title>Edit your profile</title>
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-[84px] bg-neutral-neutral_border__day_ md:gap-[63px] sm:gap-[42px]">
        <Header />
        <div className="flex w-full max-w-md sm:min-w-[310px] min-w-[320px] min-h-[500px] items-center justify-center bg-white-a700 border border-neutral-neutral_border__day_ rounded-[16px] p-[34px] md:w-full sm:p-8">
          <div className="w-full flex flex-col items-center gap-[22px]">
            <div className="mt-1 flex w-full max-w-xs flex-col items-center gap-2 md:w-full">
              <div className="flex flex-col items-center self-stretch">
                <Heading
                  size="heading_h5"
                  as="h1"
                  className="!text-neutral-neutral_1100__day_"
                >
                  Edit Profile
                </Heading>
              </div>
            </div>

            <div className="flex flex-col items-center gap-[18px] self-stretch">
              <div className="flex flex-col items-center gap-4">
                <div className="w-full">
                  <Text
                    as="h2"
                    className="font-semibold text-lg mb-4 bg-gradient-to-r from-[#5c8fff] to-[#c14bff] bg-clip-text text-transparent"
                  >
                    Profile Image
                  </Text>
                  <div className="border-b-2 border-gray-100 w-full mb-4"></div>
                  <div className="flex flex-col items-center gap-4">
                    <div className="relative">
                      <Img
                        src={avatar || "/Images/Dashboard-img/topbar.png"}
                        alt="Profile"
                        className="w-24 h-24 rounded-full object-cover"
                      />

                      {avatar && (
                        <div
                          className="absolute top-0 right-0 p-1 bg-gray-800 rounded-full cursor-pointer"
                          onClick={handleRemoveImage}
                        >
                          <Img
                            src="./Images/CloseCircle.svg"
                            alt="Remove Icon"
                            className="w-4 h-4"
                          />
                        </div>
                      )}
                    </div>

                    <Input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="self-stretch text-center"
                    />
                  </div>
                </div>

                <div className="w-full mt-4">
                  <Text
                    as="h2"
                    className="font-semibold text-lg mb-4 bg-gradient-to-r from-[#5c8fff] to-[#c14bff] bg-clip-text text-transparent"
                  >
                    Profile Information
                  </Text>
                  <div className="border-b-2 border-gray-100 w-full mb-4"></div>
                  <div className="flex flex-col gap-4">
                    <div className="flex gap-4 w-full">
                      <div className="flex flex-col font-extralight flex-1">
                        <Text as="p" className="font-light mb-1">
                          Name
                        </Text>
                        <Input
                          shape="round"
                          type="text"
                          name="Name Field"
                          placeholder={first_name}
                          onChange={(value) =>
                            setFirst_name(value.target.value)
                          }
                          suffix={
                            <Img
                              src="./Images/SignUp-img/user.png"
                              alt="User Icon"
                              className="h-4 w-4"
                            />
                          }
                          className="w-full h-[40px] border border-solid border-neutral-neutral_border_day_"
                        />
                      </div>

                      <div className="flex flex-col justify-between font-extralight flex-1">
                        <Text as="p" className="font-light mb-1"></Text>
                        <Input
                          shape="round"
                          type="text"
                          name="Name Field"
                          placeholder={last_name}
                          onChange={(value) => setLast_name(value.target.value)}
                          suffix={
                            <Img
                              src="./Images/SignUp-img/user.png"
                              alt="User Icon"
                              className="h-4 w-4"
                            />
                          }
                          className="w-full h-[40px] border border-solid border-neutral-neutral_border_day_"
                        />
                      </div>
                    </div>

                    <div className="flex flex-col font-extralight items-start gap-1 w-full">
                      <Text as="p" className="font-light">
                        E-mail
                      </Text>
                      <Input
                        shape="round"
                        type="email"
                        name="Email Field"
                        placeholder={email}
                        onChange={(value) => setEmail(value.target.value)}
                        suffix={
                          <Img
                            src="./Images/sms.png"
                            alt="SMS Icon"
                            className="h-4 w-4"
                          />
                        }
                        className="w-full h-[40px] border border-solid border-neutral-neutral_border_day_"
                      />
                    </div>

                    <div className="flex flex-col items-start gap-1 font-extralight w-full">
                      <Text as="p" className="font-light">
                        Phone Number
                      </Text>
                      <Input
                        shape="round"
                        type="tel"
                        name="Phone Field"
                        placeholder={phone}
                        onChange={(value) => setPhone(value.target.value)}
                        suffix={
                          <Img
                            src="./Images/SignUp-img/call.png"
                            alt="Call Icon"
                            className="h-4 w-4"
                          />
                        }
                        className="w-full h-[40px] border border-solid border-neutral-neutral_border_day_"
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full mt-6">
                  <Text
                    as="h2"
                    className="font-semibold text-lg mb-4 bg-gradient-to-r from-[#5c8fff] to-[#c14bff] bg-clip-text text-transparent"
                  >
                    Optimizer Options
                  </Text>
                  <div className="border-b-2 border-gray-100 w-full mb-4"></div>
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex gap-4 w-full">
                      <div className="flex-1">
                        <AntSelectBox
                          placeholder="League"
                          value={leagueOption}
                          options={leagueOptions}
                          onChange={(opt: React.SetStateAction<options[]>) =>
                            setLeagueOption(opt)
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <AntSelectBox
                          placeholder="Market"
                          value={marketOption}
                          options={marketOptions}
                          onChange={(opt: options[]) => setMarketOption(opt)}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 w-full">
                      <Input
                        name="Odds"
                        className="flex-1 h-[40px] border border-solid border-neutral-neutral_border_day_ rounded-lg"
                        placeholder={oddsOption || "Odds"}
                        onChange={(value) => setOddsOption(value.target.value)}
                      />
                      <div className="flex-1">
                        {/* Placeholder for symmetry */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-6">
                  <Text
                    as="h2"
                    className="font-semibold text-lg mb-4 bg-gradient-to-r from-[#5c8fff] to-[#c14bff] bg-clip-text text-transparent"
                  >
                    Arbitrage Options
                  </Text>
                  <div className="border-b-2 border-gray-100 w-full mb-4"></div>
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex gap-4 w-full">
                      <Input
                        name="Min Profit %"
                        placeholder={minProfitOption || "Min Profit %"}
                        className="flex-1 h-[40px] border border-solid border-neutral-neutral_border_day_ rounded-lg"
                        onChange={(value) =>
                          setMinProfitOption(value.target.value)
                        }
                      />
                      <div className="flex-1">
                        <AntSelectBox
                          placeholder="League"
                          value={arbLeagueOption}
                          options={leagueOptions}
                          onChange={(opt: options[]) => setArbLeagueOption(opt)}
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 w-full">
                      <div className="flex-1">
                        <AntSelectBox
                          placeholder="Market"
                          value={arbMarketOption}
                          options={marketOptions}
                          onChange={(opt: options[]) => setArbMarketOption(opt)}
                        />
                      </div>
                      <div className="flex-1">
                        <AntSelectBox
                          placeholder="Books"
                          value={arbBookOption}
                          options={bookOptions}
                          onChange={(opt: options[]) => setArbBookOption(opt)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-6">
                  <Text
                    as="h2"
                    className="font-semibold text-lg mb-4 bg-gradient-to-r from-[#5c8fff] to-[#c14bff] bg-clip-text text-transparent"
                  >
                    Positive Ev Options
                  </Text>
                  <div className="border-b-2 border-gray-100 w-full mb-4"></div>
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex gap-4 w-full">
                      <div className="flex-1">
                        <AntSelectBox
                          placeholder="League"
                          value={positiveEvLeagueOption}
                          options={leagueOptions}
                          onChange={(opt: React.SetStateAction<options[]>) =>
                            setPositiveEvLeagueOption(opt)
                          }
                        />
                      </div>
                      <div className="flex-1">
                        <AntSelectBox
                          placeholder="Market"
                          value={positiveEvMarketOption}
                          options={marketOptions}
                          onChange={(opt: options[]) =>
                            setPositiveEvMarketOption(opt)
                          }
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 w-full">
                      <Input
                        name="Odds"
                        className="flex-1 h-[40px] border border-solid border-neutral-neutral_border_day_ rounded-lg"
                        placeholder={positiveEvOddsOption || "Odds"}
                        onChange={(value) =>
                          setPositiveEvOddsOption(value.target.value)
                        }
                      />
                      <div className="flex-1">
                        {/* Placeholder for symmetry */}
                      </div>
                    </div>
                  </div>
                </div>

                <Button
                  size="sm"
                  variant="gradient"
                  shape="round"
                  color="blue_A200_purple_A200"
                  onClick={handleSave}
                  className="self-stretch font-semibold text-white-a700 mt-6"
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
