import { useLazyQuery } from "@apollo/client";
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import { useAuth } from "components/auth/AuthProvider";
import { GOOGLE_SIGN_IN_CLIENT_ID } from "components/const/variables";
import { SUBMIT_GOOGLE_LOGIN_REQUEST } from "components/queries/allQueries";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
const loginToast = "login-toast";

export const GoogleSignIn = () => {
  const { storeTokens } = useAuth();
  const navigate = useNavigate();
  const [sendLoginRequest, { error }] = useLazyQuery(
    SUBMIT_GOOGLE_LOGIN_REQUEST,
  );

  const handleLogin = async (token: string) => {
    try {
      toast.loading("Logging in...", { id: loginToast });
      const response = await sendLoginRequest({
        variables: {
          token: token,
        },
      });
      if (response && response.data.googleLogin) {
        if (response.data.googleLogin.pass) {
          storeTokens({
            accessToken: response.data.googleLogin.access_token,
            refreshToken: response.data.googleLogin.refresh_token,
          });
          toast.success("Successfully logged in!", { id: loginToast });
          navigate("/Dashboard");
        } else {
          toast.error("Login failed: " + response.data.googleLogin.error, {
            id: loginToast,
          });
          console.error("Login failed:", response.data.googleLogin.error);
        }
      }
    } catch (err) {
      toast.error("Error logging in. Please try again.", { id: loginToast });
      console.error("Error logging in:", err);
    }
  };

  const onSuccess = (response: CredentialResponse) => {
    const token = response.credential;
    if (!token) return;
    handleLogin(token);
    console.log("Login Success! Current User:", response.clientId);
  };

  const onFailure = () => {
    console.error("Login failed:");
    toast.error("Something went wrong", { id: loginToast });
  };

  if (error) toast.error("Something went wrong", { id: loginToast });

  return (
    <div>
      <GoogleOAuthProvider clientId={GOOGLE_SIGN_IN_CLIENT_ID}>
        <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
      </GoogleOAuthProvider>
    </div>
  );
};
