import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Img } from 'components/HomeCom/Img';
import { Heading } from 'components/Heading';

const InfiniteSlider: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      }
    ]
  };

  // Sportsbook logos
  const sportsbooks = [
    "DraftKings", "FanDuel", "BetMGM", "Caesars", "Bet365", 
    "ESPNBet", "Fanatics", "BetRivers", "Pinnacle", "Hardrock"
  ];

  return (
    <div className="slider-container w-full">
      <Heading
        as="h1"
        className="text-center text-white-a700 uppercase mb-5 md:mb-6 text-[12px] font-poppins font-thin md:text-4xl lg:text-sm"
      >
        SUPPORTED SPORTSBOOKS
      </Heading>
      
      <Slider {...settings}>
        {sportsbooks.map((book, index) => (
          <div key={index} className="p-2">
            <div className='bg-blue_gray-900_01 px-2 py-5 rounded-lg border-green-500 border border-x-0 border-y-emerald-700'>
              <Img 
                src={`./Images/BooksIcon/${book.toLowerCase()}.png`} 
                alt={`${book} Logo`} 
                className="h-8 object-contain bg-blue_gray-900_01 w-[120%] rounded" 
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default InfiniteSlider;
