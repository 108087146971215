import { Helmet } from "react-helmet";
import { Button, Heading } from "../../../../../components";
import Header from "../../../../../components/Header";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import React, { useCallback } from "react";
import { CardActions } from "@mui/material";
import { SubscribeButton } from "components/Button/stripe";
import { useAuth } from "components/auth/AuthProvider";
import { useLazyQuery } from "@apollo/client";
import { REQUEST_STRIPE_EDIT_SUBSCRIPTION } from "components/queries/allQueries";
import { toast } from "sonner";
const stripeToast = "stripe-checkout-error";

const cards = [
  {
    title: "Starter",
    description: "Quick Video messages",
    price: "Free",
    features: [
      "1 Free Arbitrage",
      "1 Free EV Play",
      "Free Snapshot of our Optimizer",
    ],
    priceId: <SubscribeButton priceId={"price_1R16z6A1OtqO597jPYlfOdip"} />,
  },
  {
    title: "Business",
    description: "Advanced recording & analytics",
    price: "$99.99 ",
    detail: "USD/CREATOR/ MO (anually)",
    features: [
      "Full Access to Arbitrage",
      "Full Access to EV Plays",
      "Full Access to Optimizer",
      "Live Feed",
    ],
    priceId: <SubscribeButton priceId={"price_1QzfqCA1OtqO597jcjvaU7t3"} />,
  },
  {
    title: "Enterprise",
    description: "Advanced admin & security",
    price: "Let's Talk",
    features: [
      "Unlimited Members ",
      "Custom White Label Experience",
      "Custom Data Curation",
    ],
    priceId: (
      <Button
        size="sm"
        className="bg-violet-600 text-white-a700 hover:bg-opacity-65"
        onClick={() =>
          (window.location.href =
            "mailto:team@updownleftright.com?subject=Enterprise Membership")
        }
      >
        Contact Us
      </Button>
    ),
  },
];

function PaymentOpt() {
  //const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = React.useState(0);
  const { tokens } = useAuth();
  const [sendRequestStripeSubEdit, { error }] = useLazyQuery(
    REQUEST_STRIPE_EDIT_SUBSCRIPTION,
  );

  const handleSubscriptions = useCallback(async () => {
    if (!tokens.accessToken) {
      toast.error("Error requesting Stripe checkout", {
        id: stripeToast,
      });
      return;
    }
    const response = await sendRequestStripeSubEdit({
      variables: {
        token: tokens.accessToken,
      },
    });
    if (response.data.createEditSubscription.error) {
      toast.error(response.data.createEditSubscription.error, {
        id: stripeToast,
      });
      return;
    }
    if (response.data.createEditSubscription.edit_subscription_link) {
      window.location.href =
        response.data.createEditSubscription.edit_subscription_link;
    }
  }, [tokens, sendRequestStripeSubEdit]);

  if (error) {
    toast.error("Error requesting Stripe checkout", {
      id: stripeToast,
    });
  }

  return (
    <>
      <Helmet>
        <title>Choose the plan that fits your needs</title>
      </Helmet>
      <div className="flex w-full min-h-screen flex-col items-center gap-10 bg-neutral-neutral_border__day_">
        <Header />

        <Button
          size="sm"
          className="bg-violet-600 text-white-a700 hover:bg-opacity-65"
          onClick={handleSubscriptions}
        >
          Manage Subscriptions
        </Button>

        <Heading
          size="heading4xl"
          as="h1"
          className="text-gray-900 text-center"
        >
          Choose the plan that fits your needs.
        </Heading>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 4,
            height: "35rem",
            marginTop: "2rem",
            maxWidth: "900px",
            width: "100%",
          }}
        >
          {cards.map((card, index) => (
            <Card
              key={index}
              sx={{
                width: "280px",
                borderRadius: "20px",
                backgroundColor: "#EFF0FF",
                transition: "0.3s",
                "&:hover": {
                  boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                  transform: "scale(1.05)",
                },
              }}
            >
              <CardActionArea
                onClick={() => setSelectedCard(index)}
                data-active={selectedCard === index ? "" : undefined}
                sx={{
                  height: "100%",
                  backgroundColor: "#EFF0FF",
                  "&[data-active]": {
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "action.selectedHover",
                    },
                  },
                }}
              >
                <CardContent sx={{ height: "100%", textAlign: "center" }}>
                  <Typography variant="h5" className="pt-4">
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className="pl-4"
                  >
                    {card.description}
                  </Typography>

                  <Typography variant="h6" color="black" className="pt-3 ">
                    {card.price}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ fontSize: "0.5rem" }}
                    className="pt-6 "
                  >
                    {card.detail}
                  </Typography>
                  <CardActions
                    sx={{ justifyContent: "center", paddingTop: "30px" }}
                  >
                    {card.priceId}
                  </CardActions>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="pt-4"
                  >
                    <ul
                      style={{
                        listStyleType: "disc",
                        paddingLeft: "20px",
                        textAlign: "left",
                      }}
                    >
                      {card.features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </div>
    </>
  );
}

export default PaymentOpt;
