import React, {
  useEffect,
  useState,
  ReactNode,
  useContext,
  useCallback,
} from "react";
import { useLazyQuery } from "@apollo/client";
import { REFRESH_TOKEN_REQUEST } from "components/queries/allQueries";
import LocationComponent from "components/location/locationProvider";
import { toast } from "sonner";

const logoutToast = 'logout-toast';

// Define types for tokens and context
interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

interface AuthContextType {
  tokens: Tokens;
  storeTokens: (newTokens: Tokens) => void;
  logout: (from: string) => void;
  userState: string;
  userEmail: string;
  userFirstName: string;
  userLastName: string;
  userPhone: string;
  userAvatar: string;
  userFilters: filters | undefined;
  userSubscription?: any;
}

type filters = {
  optimizer: {
    league: string[];
    market: string[];
    odds: string;
  };
  arbitrage: {
    minimumProfit: string;
    sports: string[];
    markets: string[];
    books: string[];
  };
  positiveEv: {
    sports: string[];
    markets: string[];
    odds: string;
  };
};

const AuthContext = React.createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userState, setUserState] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const [userFilters, setUserFilters] = useState<filters>();
  const [userSubscription, setUserSubscription] = useState<any>();

  const handleLocationRetrieved = (state: string) => {
    setUserState(state); // Set the state in HomePage component
  };

  const [sendRefreshRequest] = useLazyQuery(REFRESH_TOKEN_REQUEST);
  const [tokens, setTokens] = useState<Tokens>({
    accessToken: null,
    refreshToken: null,
  });

  const logout = useCallback((from: string) => {
    console.log("logout has been called", from);
    toast.loading("Logging out...", { id: logoutToast });
    setTokens({
      accessToken: null,
      refreshToken: null,
    });
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    toast.success("Successfully logged out!", { id: logoutToast });
  }, []);

  // Function to store tokens in local storage
  const storeTokens = useCallback((newTokens: Tokens) => {
    setTokens(newTokens);
    localStorage.setItem("accessToken", newTokens.accessToken || "");
    localStorage.setItem("refreshToken", newTokens.refreshToken || "");
    if (newTokens.accessToken) {
      const payload = decodeJwtPayload(newTokens.accessToken);
      setUserEmail(payload.email);
      setUserFirstName(payload.first_name);
      setUserLastName(payload.last_name);
      setUserPhone(payload.phone);
      setUserAvatar(payload.avatar);
      setUserFilters(payload?.filters);
      if (payload?.subscription) {
        setUserSubscription(payload.subscription);
      }
    }
  }, []);

  // Wrap refreshAccessToken in useCallback to prevent unnecessary re-renders
  const refreshAccessToken = useCallback(async () => {
    try {
      const response = await sendRefreshRequest({
        variables: {
          refresh_token: tokens.refreshToken,
        },
      });

      // Handle the response here
      if (!response?.data?.refresh?.error) {
        console.log("Refresh successful", response.data.refresh);
        storeTokens({
          accessToken: response.data.refresh.access_token,
          refreshToken: response.data.refresh.refresh_token,
        });
        /*
        const payload = decodeJwtPayload(response.data.refresh.access_token);
        setUserEmail(payload.email);
        setUserFirstName(payload.first_name);
        setUserLastName(payload.last_name);
        setUserPhone(payload.phone);
        setUserAvatar(payload.avatar);
        */
      } else {
        console.error("Refresh failed:", response.data.refresh.error);
        logout("refresh failure");
      }
    } catch (err) {
      console.error("Error refreshing tokens:", err);
    }
  }, [sendRefreshRequest, tokens.refreshToken, storeTokens, logout]);

  // Load tokens from local storage on mount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (accessToken && refreshToken) {
      setTokens({ accessToken, refreshToken });
    }
  }, []);

  // Set an interval to refresh the access token every 15 minutes
  useEffect(() => {
    const interval = setInterval(
      () => {
        if (tokens.refreshToken) {
          refreshAccessToken();
        }
      },
      1 * 60 * 1000,
    ); // Refresh every 15 minutes

    return () => clearInterval(interval); // Cleanup on unmount
  }, [refreshAccessToken, tokens.refreshToken]);

  return (
    <AuthContext.Provider
      value={{
        tokens,
        storeTokens,
        logout,
        userState,
        userEmail,
        userFirstName,
        userLastName,
        userPhone,
        userAvatar,
        userFilters,
        userSubscription,
      }}
    >
      <LocationComponent onLocationRetrieved={handleLocationRetrieved} />
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the Auth context
const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

function decodeJwtPayload(token: string): any {
  const payloadBase64 = token.split(".")[1]; // Extract payload
  const payloadJson = atob(payloadBase64); // Decode base64
  return JSON.parse(payloadJson); // Parse JSON
}

export { AuthProvider, useAuth };
