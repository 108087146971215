export const GRAPHQL_API_DEV_PROD = "https://prod.updownleftright.io/";
export const GRAPHQL_API_DEV = "https://dev.updownleftright.io/";
export const GRAPHQL_API_LOCAL = "http://localhost:4000/";
export const ENVIROMENT = process.env.REACT_APP_API_ENVIROMENT || "dev";
export const GOOGLE_SIGN_IN_CLIENT_ID =
  process.env.REACT_APP_API_GOOGLE_CLIENT_ID || "";
export const GOOGLE_SIGN_IN_CLIENT_SECRET =
  process.env.REACT_APP_API_GOOGLE_CLIENT_SECRET || "";

export const STRIPE_PK = process.env.REACT_APP_API_STRIPE_PK || "";


export function getGraphQlApiURL(): string {
  switch (ENVIROMENT) {
    case "dev":
      return GRAPHQL_API_DEV;
    case "local":
      return GRAPHQL_API_LOCAL;
    case "prod":
      return GRAPHQL_API_DEV_PROD;
    default:
      return GRAPHQL_API_DEV;
  }
}

export type options = {
  label: string;
  value: string;
};

export const marketOptions: options[] = [
  { label: "Over/Under", value: "o/u" },
  { label: "Spread", value: "sp" },
  { label: "Moneyline", value: "ml" },
  { label: "Unknown", value: "uk" },
];

export const bookOptions: options[] = [
  { label: "Fanduel", value: "fanduel" },
  { label: "Bet365", value: "bet365" },
  { label: "Draftkings", value: "draftkings" },
  { label: "ESPNBet", value: "espnbet" },
  { label: "Fanatics", value: "fanatics" },
  { label: "BetMGM", value: "betmgm" },
  { label: "BetRivers", value: "betrivers" },
  { label: "Pinnacle", value: "pinnacle" },
  { label: "Hardrock", value: "hardrock" },
];

export const leagueOptions = [
  { label: "WNBA", value: "wnba" },
  { label: "NBA", value: "nba" },
  { label: "NFL", value: "nfl" },
  { label: "MLB", value: "mlb" },
];
