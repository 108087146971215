import React from "react";

const shapes = {
  round: "rounded-[10px]",
} as const;

const variants = {
  tarOutlineWhiteA70014: "!border-white-a700_14 border border-solid bg-deep_purple-a400_2d",
} as const;

const sizes = {
  xs: "h-[152px] p-5 text-[16px]",
} as const;

type TextAreaProps = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  "size" | "prefix" | "type" | "onChange"
> &
  Partial<{
    className?: string;
    name?: string;
    placeholder?: string;
    onChange?: (value: string) => void;
    shape?: keyof typeof shapes;
    variant?: keyof typeof variants;
    size?: keyof typeof sizes;
    prefix?: React.ReactNode; // Añadimos la propiedad prefix
  }>;

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = "",
      name = "",
      placeholder = "",
      shape,
      size = "xs",
      variant = "tarOutlineWhiteA70014",
      prefix, // Añadimos la propiedad prefix aquí
      onChange,
      ...restProps
    },
    ref
  ) => {
    const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
      if (onChange) onChange(e.target.value);
    };

    return (
      <div className={`relative ${className} ${shape ? shapes[shape] : ""} ${size ? sizes[size] : ""} ${variant ? variants[variant] : ""}`}>
        {prefix && <div className="absolute left-3 top-3">{prefix}</div>} {/* Ajustamos la posición del prefix */}
        <textarea
          ref={ref}
          className={`pl-10 pr-4 py-2 bg-transparent w-full`}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          {...restProps}
        />
      </div>
    );
  }
);

export { TextArea };
