/* eslint-disable react-hooks/exhaustive-deps */
import "./Buttons.css";
import { Helmet } from "react-helmet";
import { Text, Img, Input } from "../../../components/Dashboard";
import { ReactTable } from "../../../components/Dashboard/ReactTable";

import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";
import { useLazyQuery } from "@apollo/client";
import { FETCH_OPTIMIZER_REQUEST } from "components/queries/allQueries";
import { useAuth } from "components/auth/AuthProvider";

import { Select } from "antd";
import Header from "components/Dashboard/Header";
import { CloseCircle, Refresh } from "iconsax-react";
import {
  americanToDecimalOdds,
  decimalToAmericanOdds,
} from "components/functions/math";
import { updateState } from "components/functions/states";
import { options } from "components/const/variables";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

const AntSelectBox = ({ ...props }) => {
  return (
    <Select mode="multiple" maxTagCount={2} {...props} style={{ width: "25%" }}>
      {leagueOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

const leagueOptions = [
  { label: "WNBA", value: "wnba" },
  { label: "NBA", value: "nba" },
  { label: "NFL", value: "nfl" },
  { label: "MLB", value: "mlb" },
];

const marketOptions: options[] = [
  { label: "Over/Under", value: "o/u" },
  { label: "Spread", value: "sp" },
  { label: "Moneyline", value: "ml" },
  { label: "Unknown", value: "uk" },
];

type Table3RowType = {
  averageLabel: any;
  teamLabel: string;
  bettypeLabel: string;
  booksLabel: any;
  handicapLabel: string;
  idLabel: string;
  leagueLabel: string;
  marketLabel: string;
  nameLabel: string;
  startTimeLabel: string;
  startedLabel: boolean;
  statesLabel: any;
  tagsLabel: string[];
};

export type OptimizerData = {
  away: string;
  bettype: string;
  books: OPTBook;
  handicap: string;
  home: string;
  id: string;
  league: string;
  market: string;
  name: string;
  startTime: string;
  started: boolean;
  states?: any;
  tags: string[];
};

type OPTBook = {
  [key: string]: OptimizerBook;
};

type OptimizerBook = {
  ao: number;
  do: number;
  ml: string;
  wl: string;
};

export function arraysMatchUnordered(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export function convertMarketOption(opt: string): string {
  switch (opt) {
    case "OVER":
      return "Over";
    case "UNDER":
      return "Under";
    case "MONEYLINE":
      return "ML";
    case "SPREAD":
      return "SP";
    default:
      return "UK";
  }
}

export function calculateAverageAO(odds: any): string {
  const oddsArray = Object.values(odds); // Extract the values into an array
  if (oddsArray.length === 0) {
    return "0"; // Avoid division by zero
  }

  const totalAO: any = oddsArray.reduce(
    (sum: number, item: any) => sum + americanToDecimalOdds(item.ao),
    0,
  );
  const averageAO = totalAO / oddsArray.length;

  return decimalToAmericanOdds(averageAO);
}

export default function OptimizerPage() {
  const [keywordOption, setKeywordOption] = useState("");
  const { tokens, userState, userFilters } = useAuth();
  const [odds, setOdds] = useState<Table3RowType[]>([]);
  const [filteredOdds, setFilteredOdds] = useState<Table3RowType[]>([]);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [leagueOption, setLeagueOption] = useState<options[]>(
    userFilters?.optimizer?.league?.map((league: string) => 
      leagueOptions.find(option => option.label === league)
    ).filter((option): option is options => option !== undefined) || []
  );
  const [marketOption, setMarketOption] = useState<options[]>(
    userFilters?.optimizer?.market?.map((market: string) => 
      marketOptions.find(option => option.label === market)
    ).filter((option): option is options => option !== undefined) || []
  );
  const [countdown, setCountdown] = useState(15);
  const [fetchOptimizer] = useLazyQuery(FETCH_OPTIMIZER_REQUEST);
  const [isLive] = useState(true);
  const navigate = useNavigate();

  const handleFetchOptimizer = useCallback(async () => {
    try {
      const response = await fetchOptimizer({
        variables: {
          accessToken: tokens.accessToken,
          filter: {
            sports: leagueOption.map((option) => option.value),
            markets: marketOption.map((option) => option.value),
          },
        },
      });
      if (response.error) {
        console.error("Fetching optimizer failed:", response.error);
        setResponseMessage("Error fetching optimizer data");
      } else if (response.data.fetchOptimizer.success) {
        const newOdds: Table3RowType[] = [];
        for (const odds of response.data.fetchOptimizer
          .data as OptimizerData[]) {
          newOdds.push({
            averageLabel: calculateAverageAO(odds.books),
            teamLabel: `${odds.away} @ ${odds.home}`,
            bettypeLabel: convertMarketOption(odds.bettype),
            booksLabel: odds.books,
            handicapLabel: odds.handicap,
            idLabel: odds.id,
            leagueLabel: odds.league,
            marketLabel: odds.market,
            nameLabel: odds.name,
            startTimeLabel: odds.startTime,
            startedLabel: odds.started,
            statesLabel: odds.states,
            tagsLabel: odds.tags,
          });
        }
        if (!arraysMatchUnordered(newOdds, odds)) {
          setOdds(newOdds);
        }
        setResponseMessage(response.data.fetchOptimizer.message || "Data updated successfully");
        console.log("Fetching optimizer successful", response.data.login);
      } else {
        console.error("Fetching optimizer failed:", response.data.login.error);
        setResponseMessage("Failed to fetch optimizer data");
      }
    } catch (err) {
      console.error("Error fetching optimizer:", err);
      setResponseMessage("Error occurred while fetching data");
    }
    setCountdown(15);
  }, [odds, fetchOptimizer, marketOption, tokens]);

  useEffect(() => {
    let kw = keywordOption.trim().toLowerCase();
    let filteredOdds: Table3RowType[] = odds;
    if (!isLive) {
      filteredOdds = filteredOdds.filter((odds) => !odds.startedLabel);
    }

    if (marketOption && marketOption.length > 0) {
      filteredOdds = filteredOdds.filter((odds) =>
        marketOption.some(
          (option) => odds.bettypeLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (leagueOption && leagueOption.length > 0) {
      filteredOdds = filteredOdds.filter((odds) =>
        leagueOption.some(
          (option) => odds.leagueLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (kw && kw !== "") {
      filteredOdds = filteredOdds.filter(
        (odds) =>
          odds.nameLabel.toLowerCase().includes(kw) ||
          odds.leagueLabel.toLowerCase().includes(kw) ||
          odds.marketLabel.toLowerCase().includes(kw),
      );
    }

    setFilteredOdds(filteredOdds);
  }, [
    isLive,
    odds,
    setFilteredOdds,
    marketOption,
    leagueOption,
    keywordOption,
  ]);

  useEffect(() => {
    handleFetchOptimizer();
    const fa = setInterval(() => {
      //handleFetchOptimizer();
      setCountdown(15);
    }, 15 * 1000); // every 15 seconds
    return () => clearInterval(fa);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0,
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    console.log("leagueOption changed", leagueOption);
  }, [leagueOption]);

  useEffect(() => {
    console.log("marketOption changed", marketOption);
  }, [marketOption]);

  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("leagueLabel", {
        cell: (info) => (
          <div className="flex items-center gap-2">
            {info.getValue<any>() === "WNBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/wnba.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/nba.svg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NFL" && (
              <img
                alt=""
                src="./Images/BooksIcon/nfl.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "MLB" && (
              <img
                alt=""
                src="./Images/BooksIcon/mlb.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_ "
            >
              {info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            League
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("teamLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Game
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("bettypeLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("handicapLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3  text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Handicap
          </Text>
        ),
        meta: { width: "7%" },
      }),
      table3ColumnHelper.accessor("marketLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Market
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("nameLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Name
          </Text>
        ),
        meta: { width: "9%" },
      }),
      table3ColumnHelper.accessor("averageLabel", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.averageLabel;
          let objB = rowB.original.averageLabel;
          if (!objA) {
            objA = -1000;
          }
          if (!objB) {
            objB = -1000;
          }
          return objA > objB ? 1 : objA < objB ? -1 : 0;
        },
        cell: (info) => {
          const currentValue =
            Number(Number(info.getValue<any>()).toFixed(0)) || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/Dashboard-img/LogoArb.png"
            style={{ width: "30%", height: "15%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fanduel", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["fanduel"];
          let objB = rowB.original.booksLabel["fanduel"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/fanduel.png"
            style={{ width: "30%", height: "15%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.draftkings", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["draftkings"];
          let objB = rowB.original.booksLabel["draftkings"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/draftkings.png"
            style={{ width: "45%", height: "20%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.betmgm", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["betmgm"];
          let objB = rowB.original.booksLabel["betmgm"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/BetMGM.png"
            style={{ width: "35%", height: "15%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.bet365", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["bet365"];
          let objB = rowB.original.booksLabel["bet365"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/bet365.png"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fanatics", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["fanatics"];
          let objB = rowB.original.booksLabel["fanatics"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/fanatics.webp"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.espnbet", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["espnbet"];
          let objB = rowB.original.booksLabel["espnbet"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/espnbet.jpeg"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.caesars", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["caesars"];
          let objB = rowB.original.booksLabel["caesars"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/caesars.webp"
            style={{ width: "40%", height: "3%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.pinnacle", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["pinnacle"];
          let objB = rowB.original.booksLabel["pinnacle"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/Pinnacle.jpeg"
            style={{ width: "35%", height: "8%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.fliff", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["fliff"];
          let objB = rowB.original.booksLabel["fliff"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;
          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/getfliff.jpeg"
            style={{ width: "35%", height: "12%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.betrivers", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["betrivers"];
          let objB = rowB.original.booksLabel["betrivers"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/BetRivers.jpeg"
            style={{ width: "35%", height: "7%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
      table3ColumnHelper.accessor("booksLabel.hardrock", {
        sortingFn: (rowA, rowB) => {
          let objA = rowA.original.booksLabel["hardrock"];
          let objB = rowB.original.booksLabel["hardrock"];
          if (!objA) {
            objA = { ao: -1000 };
          }
          if (!objB) {
            objB = { ao: -1000 };
          }
          return objA.ao > objB.ao ? 1 : objA.ao < objB.ao ? -1 : 0;
        },
        cell: (info) => {
          const currentValue = info.getValue<any>()?.ao || 0;

          const allValues = Object.values(info.row.original.booksLabel).map(
            (book: any) => book?.ao || 0,
          );

          const maxValue = Math.max(...allValues);

          return (
            <button
              className="w-[100%] rounded-lg h-[28px] border"
              style={{
                backgroundColor:
                  currentValue === maxValue
                    ? "rgba(80, 209, 178, 0.2)"
                    : currentValue < 0
                      ? "#E8EDF2"
                      : "#E8EDF2",
                color:
                  currentValue === maxValue
                    ? "#2B9B80"
                    : currentValue < 0
                      ? "black"
                      : "black",
              }}
              disabled={!info.getValue<any>()}
              onClick={() =>
                window.open(
                  updateState(info.getValue<any>()?.wl, userState) || "#",
                  "_blank",
                )
              }
            >
              {currentValue || 0}
            </button>
          );
        },
        header: (info) => (
          <img
            alt=""
            src="./Images/BooksIcon/hardrock.webp"
            style={{ width: "50%", height: "4%", marginLeft: "25%" }}
          />
        ),
        meta: { width: "4%" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title> Sports Optimizer Betting - Find the Best Odds</title>
        <meta
          name="description"
          content="Engage in sports optimizer betting and ensure the best payouts with our comprehensive odds comparison from top bookmakers like MGM, FD, DK, and ESPN."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="w-full bg-neutral-neutral_100__day_">
          <div>
            <div className="relative h-[90px] md:h-auto">
              <Header />
            </div>

            <div className="ml-4 h-px bg-neutral-neutral_border__day_ md:ml-0" />

            <div className="mr-7 flex items-start gap-[30px] md:mr-0">
              <div className="flex-1">
                <div className="flex  ml-5 py-2  md:self-stretch md:px-5">
                  <Input
                    name="Search Bar"
                    placeholder="Enter your keyword..."
                    value={keywordOption}
                    onChange={(kw) => setKeywordOption(kw.target.value)}
                    prefix={
                      <Img
                        src="/Images/Dashboard-img/search.png"
                        alt="Contrast"
                        className="my-1 h-[100%] w-[100%]"
                      />
                    }
                    suffix={
                      keywordOption.length > 0 ? (
                        <CloseCircle onClick={() => setKeywordOption("")} />
                      ) : null
                    }
                    className="flex h-[48px] w-[18%] items-center justify-center gap-3  rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[10px] text-blue_gray-300 md:w-full  md:px-5"
                  />
                  <AntSelectBox
                    placeholder="League"
                    value={leagueOption}
                    onChange={(opt: React.SetStateAction<options[]>) =>
                      setLeagueOption(opt)
                    }
                    className="ml-3"
                  />

                  <AntSelectBox
                    placeholder="Market"
                    value={marketOption}
                    options={marketOptions}
                    onChange={(opt: options[]) => setMarketOption(opt)}
                    className="ml-3  w-[15%]"
                  />
                  <Input
                    placeholder="Odds"
                    className="flex h-[48px] w-[18%] items-center justify-center gap-3 ml-3  rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[10px] text-blue_gray-300 md:w-full  md:px-5"
                  />
                </div>

                <div className="rounded-[16px] ml-5 border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-5">
                  <div className="flex ">
                    <p className=" text-left mr-2 text-[14px]  font-normal text-neutral-neutral_500__day_">
                      {" "}
                      Auto refresh:{countdown}{" "}
                    </p>
                    <button onClick={handleFetchOptimizer}>
                      {" "}
                      <Refresh className="w-[15px] h-[15px] " />
                    </button>
                  </div>
                  <ReactTable
                    size="xs"
                    bodyProps={{ className: "" }}
                    cellProps={{
                      className: "border-gray-300 border-b-2 border-solid",
                    }}
                    className="mb-2 md:block md:overflow-x-auto md:whitespace-nowrap"
                    columns={table3Columns}
                    data={filteredOdds}
                  />
                  {responseMessage && (
                    <div className={`mt-4 p-3 rounded-lg ${
                      responseMessage.includes("Limited") 
                        ? "bg-[#6B46C1] border-red-500" 
                        : "bg-neutral-neutral_bg__day_ border-neutral-neutral_border__day_"
                    }`}>
                      <div className="flex justify-between items-center">
                        <p className="text-[14px] font-normal" style={{ color: "white" }}>
                          {responseMessage}
                        </p>
                        {responseMessage.includes("Limited") && (
                          <button
                            onClick={() => navigate("/PaymentOpt")}
                            className="ml-4 px-6 py-2.5 bg-gradient-to-r from-[#6B46C1] to-[#553C9A] text-white rounded-lg font-medium shadow-md transition-all duration-300 flex items-center gap-2 border border-[#6B46C1] hover:from-[#553C9A] hover:to-[#6B46C1] hover:shadow-lg"
                            style={{ color: "white" }}
                        >
                            <span className="font-medium">Upgrade Now</span>
                            <svg 
                              className="w-4 h-4" 
                              fill="none" 
                              stroke="currentColor" 
                              viewBox="0 0 24 24"
                            >
                              <path 
                                strokeLinecap="round" 
                                strokeLinejoin="round" 
                                strokeWidth={2} 
                                d="M13 7l5 5m0 0l-5 5m5-5H6" 
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
