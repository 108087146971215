import { useState, useEffect } from 'react';

const LocationComponent = ({ onLocationRetrieved }:any) => {
  const [, setState] = useState("");
  const [, setLoading] = useState(true);
  const [, setError] = useState("");

  useEffect(() => {
    const fetchLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;
            try {
              const response = await fetch(
                `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
              );
              const data = await response.json();
              const userState = data.principalSubdivision;
              
              setState(userState);
              onLocationRetrieved(userState); // Call the callback with the state
              setLoading(false);
            } catch (err) {
              setError("Failed to fetch location data");
              setLoading(false);
            }
          },
          (error) => {
            setError("Geolocation permission denied");
            setLoading(false);
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
        setLoading(false);
      }
    };

    fetchLocation();
  }, [onLocationRetrieved]);

  //if (loading) return <p>Loading location...</p>;
  //if (error) return <p>{error}</p>;
  return null
};

export default LocationComponent;
