import { gql } from "@apollo/client";

export const SUBMIT_LOGIN_REQUEST = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      access_token
      refresh_token
      pass
    }
  }
`;

export const SUBMIT_GOOGLE_LOGIN_REQUEST = gql`
  query googleLogin($token: String!) {
    googleLogin(token: $token) {
      access_token
      refresh_token
      pass
    }
  }
`;

export const SUBMIT_REGISTER_REQUEST = gql`
  query register(
    $username: String!
    $password: String!
    $phone: String!
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    register(
      username: $username
      password: $password
      email: $email
      phone: $phone
      firstName: $firstName
      lastName: $lastName
    ) {
      success
      details
    }
  }
`;

export const EDIT_SETTINGS_REQUEST = gql`
  query EditSettings($accessToken: String!, $settings: EditSettingsFields!) {
    editSettings(accessToken: $accessToken, settings: $settings) {
      error
      access_token
      refresh_token
    }
  }
`;

export const REFRESH_TOKEN_REQUEST = gql`
  query refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      access_token
      refresh_token
      error
    }
  }
`;

export const FETCH_USER_DATA_REQUEST = gql`
  query fetchUser($access_token: String!) {
    fetchUser(access_token: $access_token) {
      success
      error
      data {
        id
        username
        email
        customerId
        lastLogin
        registerDate
        subscriptions {
          id
          key
          product
          limit
          uses
          left
          expiration
          plan
          price
        }
      }
    }
  }
`;

export const FETCH_ARBITRAGE_REQUEST = gql`
  query fetchArbitrage($access_token: String!, $filter: ArbitrageFilterInput!) {
    fetchArbitrage(filter: $filter, access_token: $access_token) {
      success
      message
      data {
        id
        name
        league
        event
        teamName1
        teamName2
        market
        handicap
        amount
        payout
        live
        middle
        percent
        book1 {
          name
          url
          odds
          bet
          risk
        }
        book2 {
          name
          url
          odds
          bet
          risk
        }
      }
    }
  }
`;

export const FETCH_POSITIVE_EV_REQUEST = gql`
  query fetchPositiveEv(
    $accessToken: String!
    $filter: PositiveEvFilterInput!
  ) {
    fetchPositiveEv(access_token: $accessToken, filter: $filter) {
      success
      message
      data {
        id
        bettype
        books
        difference
        startTime
        handicap
        league
        market
        name
        book
        started
        odds
        mobile
        web
        states
      }
    }
  }
`;

export const FETCH_OPTIMIZER_REQUEST = gql`
  query FetchOptimizer($accessToken: String!, $filter: OptimizerFilterInput!) {
    fetchOptimizer(access_token: $accessToken, filter: $filter) {
      success
      message
      data {
        away
        bettype
        books
        handicap
        home
        id
        league
        market
        name
        startTime
        started
        states
        tags
      }
    }
  }
`;

export const FETCH_USER_REQUEST = gql`
  query FetchOptimizer($accessToken: String!) {
    fetchUser(access_token: $accessToken) {
      success
      data {
        optimizerSneakPeak {
          away
          bettype
          books
          handicap
          home
          id
          league
          market
          name
          startTime
          started
          states
          tags
        }
        activeOptimizer
        activePositiveEv
        activeArbs
        userData
      }
      error
    }
  }
`;

export const REQUEST_STRIPE_CHECKOUT = gql`
  query createCheckoutSession($token: String!, $priceId: String!) {
    createCheckoutSession(token: $token, priceId: $priceId) {
      checkout_link
      error
    }
  }
`;

export const REQUEST_STRIPE_EDIT_SUBSCRIPTION = gql`
  query createEditSubscription($token: String!) {
    createEditSubscription(token: $token) {
      edit_subscription_link
      error
    }
  }
`;
