/* eslint-disable react-hooks/exhaustive-deps */
import "./Buttons.css";
import { Helmet } from "react-helmet";
import { Text, Img, Input } from "../../../components/Dashboard";
import { ReactTable } from "../../../components/Dashboard/ReactTable";

import { createColumnHelper } from "@tanstack/react-table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Sidebar1 from "components/Dashboard/Sidebar1";
import { useLazyQuery } from "@apollo/client";
import { FETCH_ARBITRAGE_REQUEST } from "components/queries/allQueries";
import { useAuth } from "components/auth/AuthProvider";

import { Select, Switch } from "antd";
import Header from "components/Dashboard/Header";
import { CloseCircle, Refresh } from "iconsax-react";
import { updateState } from "components/functions/states";
import {
  options,
  bookOptions,
  marketOptions,
  leagueOptions,
} from "components/const/variables";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

interface CustomSwitchProps {
  isActive: boolean;
  activeColor: string;
  [key: string]: any;
}

const AntSelectBox = ({ ...props }) => {
  return (
    <Select mode="multiple" maxTagCount={2} {...props} style={{ width: "25%" }}>
      {leagueOptions.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  isActive,
  activeColor,
  ...props
}) => (
  <Switch
    {...props}
    checked={isActive}
    style={{
      backgroundColor: isActive ? activeColor : "",
      borderColor: isActive ? activeColor : "#B3261E",
    }}
  />
);

type Table3RowType = {
  leagueLabel: string;
  marketLabel: string;
  nameLabel: string;
  handicapLabel: string;
  betTypeLabel: string;
  wagerLabel: string;
  payoutLabel: string;
  profitLabel: string;
  liveLabel: boolean | string;
  middleLabel: boolean | string;
  booksLabel: booksLabel | string;
};

type booksLabel = {
  book1: book;
  book2: book;
};

type book = {
  name: string;
  odds: string;
  link: string;
  risk: number;
};

type ArbitrageData = {
  id: string;
  name: string;
  league: string;
  event: string;
  teamName1?: string;
  teamName2?: string;
  market: string;
  handicap: string;
  amount: string;
  payout: string;
  live: boolean;
  middle: boolean;
  percent: number;
  book1: ArbitrageBook;
  book2: ArbitrageBook;
};

type ArbitrageBook = {
  name: string;
  url: string;
  odds: string;
  bet: string;
  risk: number;
};

function arraysMatchUnordered(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

const getBookIcon = (bookName: string): string => {
  const nameMap: { [key: string]: string } = {
    Fliff: "getfliff.jpeg",
    ESPNBet: "espnbet.jpeg",
    Fanatics: "fanatics.webp",
    Caesars: "caesars.webp",
    BetRivers: "BetRivers.jpeg",
    BetMGM: "BetMGM.png",
    fanduel: "fanduel.png",
    Fanduel: "fanduel.png",
    Draftkings: "draftkings.png",
    draftkings: "draftkings.png",
    Pinnacle: "Pinnacle.jpeg",
    Hardrock: "hardrock.webp",
    Bet365: "bet365.png",
  };
  return nameMap[bookName] || "";
};

const loadingToastId = "loading-arbitrage";

export default function ArbitragePage() {
  const [keywordOption, setKeywordOption] = useState("");
  const { tokens, userState, userFilters } = useAuth();
  const [countdown, setCountdown] = useState(15);
  const [arbs, setArbs] = useState<Table3RowType[]>([]);
  const [filteredArbs, setFilteredArbs] = useState<Table3RowType[]>([]);
  const [leagueOption, setLeagueOption] = useState<options[]>(
    userFilters?.arbitrage?.sports
      ?.map((sport: string) =>
        leagueOptions.find((option) => option.label === sport),
      )
      .filter((option): option is options => option !== undefined) || [],
  );
  const [marketOption, setMarketOption] = useState<options[]>(
    userFilters?.arbitrage?.markets
      ?.map((market: string) =>
        marketOptions.find((option) => option.label === market),
      )
      .filter((option): option is options => option !== undefined) || [],
  );
  const [bookOption, setBookOption] = useState<options[]>(
    userFilters?.arbitrage?.books
      ?.map((book: string) =>
        bookOptions.find((option) => option.label === book),
      )
      .filter((option): option is options => option !== undefined) || [],
  );
  const [fetchArbitrage] = useLazyQuery(FETCH_ARBITRAGE_REQUEST);
  const [isLive, setIsLive] = useState(true);
  const [isMiddles, setIsMiddles] = useState(true);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleFetchArbitrage = useCallback(async () => {
    try {
      toast.loading("Loading arbitrage data...", { id: loadingToastId });
      const response = await fetchArbitrage({
        variables: {
          access_token: tokens.accessToken,
          filter: {
            sports: leagueOptions.map((option) => option.value),
            books: bookOption.map((option) => option.value),
            markets: marketOption.map((option) => option.value),
            minimumPercent: 1,
            includeLives: true,
            includeMiddles: true,
          },
        },
      });

      if (response.data.fetchArbitrage.success) {
        toast.success("Data loaded successfully", { id: loadingToastId });
        const newArbs: Table3RowType[] = [];
        for (const arb of response.data.fetchArbitrage
          .data as ArbitrageData[]) {
          const hc1 = arb.handicap.split(" ")[0];
          const hc2 = arb.handicap.split(" ")[2];
          newArbs.push({
            leagueLabel: arb.league.toUpperCase(),
            marketLabel: arb.market,
            nameLabel: arb.name,
            handicapLabel: `${hc1}:${hc2}`,
            betTypeLabel: `${arb.book1.bet}:${arb.book2.bet}`,
            wagerLabel: arb.amount,
            payoutLabel: arb.payout,
            profitLabel:
              (Number(arb.payout) - Number(arb.amount)).toFixed(2) + "",
            liveLabel: arb.live,
            middleLabel: arb.middle,
            booksLabel: {
              book1: {
                name: arb.book1.name,
                odds: arb.book1.odds,
                link: arb.book1.url,
                risk: arb.book1.risk,
              },
              book2: {
                name: arb.book2.name,
                odds: arb.book2.odds,
                link: arb.book2.url,
                risk: arb.book2.risk,
              },
            },
          });
        }
        if (!arraysMatchUnordered(newArbs, arbs)) {
          setArbs(newArbs);
        }
        setResponseMessage(
          response.data.fetchArbitrage.message || "Data loaded successfully",
        );
      } else {
        toast.error("Failed to fetch arbitrage data", { id: loadingToastId });
        console.error("Fetching arbitrage failed:", response.data.login.error);
        setResponseMessage(response.data.login.error);
      }
    } catch (err) {
      toast.error("Error loading data", { id: loadingToastId });
      console.error("Error fetching arbitrage:", err);
      setResponseMessage("An error occurred while fetching arbitrage data");
    }
    setCountdown(3);
  }, [arbs, bookOption, fetchArbitrage, marketOption, tokens.accessToken]);

  useEffect(() => {
    let kw = keywordOption.trim().toLowerCase();
    let filteredArbs: Table3RowType[] = arbs;
    if (!isLive) {
      filteredArbs = filteredArbs.filter((arb) => !arb.liveLabel);
    }

    if (!isMiddles) {
      filteredArbs = filteredArbs.filter((arb) => !arb.middleLabel);
    }

    if (bookOption && bookOption.length > 1) {
      filteredArbs = filteredArbs.filter(
        (arb) =>
          bookOption.some(
            (book) =>
              (arb.booksLabel as booksLabel).book1.name.toLowerCase() ===
              String(book),
          ) &&
          bookOption.some(
            (book) =>
              (arb.booksLabel as booksLabel).book2.name.toLowerCase() ===
              String(book),
          ),
      );
    }

    if (marketOption && marketOption.length > 0) {
      filteredArbs = filteredArbs.filter((arb) =>
        marketOption.some(
          (option) => arb.betTypeLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (leagueOption && leagueOption.length > 0) {
      filteredArbs = filteredArbs.filter((arb) =>
        leagueOption.some(
          (option) => arb.leagueLabel.toLowerCase() === String(option),
        ),
      );
    }

    if (kw && kw !== "") {
      filteredArbs = filteredArbs.filter(
        (arb) =>
          arb.nameLabel.toLowerCase().includes(kw) ||
          arb.leagueLabel.toLowerCase().includes(kw) ||
          arb.marketLabel.toLowerCase().includes(kw),
      );
    }
    setFilteredArbs(filteredArbs);
  }, [
    isMiddles,
    isLive,
    arbs,
    setFilteredArbs,
    bookOption,
    marketOption,
    leagueOption,
    keywordOption,
  ]);

  useEffect(() => {
    handleFetchArbitrage();

    const timer = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0,
      );
    }, 1000);

    const fa = setInterval(() => {
      handleFetchArbitrage();
    }, 3 * 1000);

    return () => {
      clearInterval(timer);
      clearInterval(fa);
    };
  }, []);

  useEffect(() => {
    console.log("leagueOption changed", leagueOption);
  }, [leagueOption]);

  useEffect(() => {
    console.log("marketOption changed", marketOption);
  }, [marketOption]);

  const table3Columns = useMemo(() => {
    const table3ColumnHelper = createColumnHelper<Table3RowType>();
    return [
      table3ColumnHelper.accessor("liveLabel", {
        cell: (info) => {
          const middleLabelValue = info.row.original.middleLabel === true;
          const isLive = info.getValue<boolean>() === true && !middleLabelValue;

          console.log("liveLabel value:", isLive);
          console.log("middleLabel value:", middleLabelValue);

          return (
            <div className="flex items-center gap-2">
              {middleLabelValue ? (
                <img
                  alt="middle"
                  src="./Images/BooksIcon/middles.png"
                  style={{ width: "12%", height: "100%" }}
                />
              ) : isLive ? (
                <img
                  alt="live"
                  src="./Images/BooksIcon/live.png"
                  style={{ width: "12%", height: "100%" }}
                />
              ) : (
                <img
                  alt="normal"
                  src="./Images/BooksIcon/Normal.png"
                  style={{
                    width: "12%",
                    height: "100%",
                    padding: 0,
                    margin: 0,
                  }}
                />
              )}
            </div>
          );
        },
        header: (info) => <Text />,
        meta: { width: "2%" },
      }),
      table3ColumnHelper.accessor("leagueLabel", {
        cell: (info) => (
          <div className="flex items-center gap-2">
            {info.getValue<any>() === "WNBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/wnba.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NBA" && (
              <img
                alt=""
                src="./Images/BooksIcon/nba.svg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "NFL" && (
              <img
                alt=""
                src="./Images/BooksIcon/nfl.jpeg"
                style={{ width: "10%", height: "15%" }}
              />
            )}
            {info.getValue<any>() === "MLB" && (
              <img
                alt=""
                src="./Images/BooksIcon/mlb.jpeg"
                style={{ width: "15%", height: "15%" }}
              />
            )}

            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_ "
            >
              {info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            League
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("nameLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Name
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("betTypeLabel", {
        cell: (info) => (
          <div>
            <Text
              as="p"
              className="text-[14px] font-semibold text-neutral-neutral_1100__day_"
            >
              {info.getValue<any>().split(":")[0]}
            </Text>

            <Text
              as="p"
              className="text-[14px] font-semibold text-neutral-neutral_1100__day_"
            >
              {info.getValue<any>().split(":")[1]}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("handicapLabel", {
        cell: (info) => (
          <div>
            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_"
            >
              {info.getValue<any>().split(":")[0]}
            </Text>
            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_"
            >
              {info.getValue<any>().split(":")[1]}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Handicap
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("marketLabel", {
        cell: (info) => (
          <Text
            as="p"
            className="text-[14px] font-normal text-neutral-neutral_1100__day_"
          >
            {info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-3 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Bet Type
          </Text>
        ),
        meta: { width: "8%" },
      }),
      // this is to get the odds from bookslabel
      table3ColumnHelper.accessor("booksLabel", {
        cell: (info) => (
          <div>
            <div className="flex gap-2 justify-start ">
              <Text
                as="p"
                className="text-[14px] font-normal text-neutral-neutral_1100__day_"
              >
                {Number(info.getValue<booksLabel>().book1.odds) > 0
                  ? `+${info.getValue<booksLabel>().book1.odds}`
                  : info.getValue<booksLabel>().book1.odds}
              </Text>
              {info.getValue<booksLabel>().book1.name === "Fliff" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/getfliff.jpeg"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "ESPNBet" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/espnbet.jpeg"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "Fanatics" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/fanatics.webp"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "Caesars" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/caesars.webp"
                  style={{ width: "18%", height: "15%" }}
                />
              )}

              {info.getValue<booksLabel>().book1.name === "BetRivers" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/BetRivers.jpeg"
                  style={{ width: "10%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "BetMGM" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/BetMGM.png"
                  style={{ width: "16%", height: "14%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "fanduel" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/fanduel.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "Fanduel" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/fanduel.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "Draftkings" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/draftkings.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "draftkings" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/draftkings.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "Pinnacle" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/Pinnacle.jpeg"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "Hardrock" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/hardrock.webp"
                  style={{ width: "25%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book1.name === "Bet365" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/bet365.png"
                  style={{ width: "25%", height: "15%" }}
                />
              )}
            </div>

            <div className="flex gap-2 justify-start  ">
              <Text
                as="p"
                className="text-[14px] font-normal text-neutral-neutral_1100__day_ "
              >
                {Number(info.getValue<booksLabel>().book2.odds) > 0
                  ? `+${info.getValue<booksLabel>().book2.odds}`
                  : info.getValue<booksLabel>().book2.odds}
              </Text>
              {info.getValue<booksLabel>().book2.name === "Fliff" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/getfliff.jpeg"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "ESPNBet" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/espnbet.jpeg"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "Fanatics" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/fanatics.webp"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "Caesars" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/caesars.webp"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "BetRivers" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/BetRivers.jpeg"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "BetMGM" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/BetMGM.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "fanduel" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/fanduel.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "Fanduel" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/fanduel.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "Draftkings" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/draftkings.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "draftkings" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/draftkings.png"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "Pinnacle" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/Pinnacle.jpeg"
                  style={{ width: "18%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "Hardrock" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/hardrock.webp"
                  style={{ width: "25%", height: "15%" }}
                />
              )}
              {info.getValue<booksLabel>().book2.name === "Bet365" && (
                <img
                  alt=""
                  src="./Images/BooksIcon/bet365.png"
                  style={{ width: "25%", height: "15%" }}
                />
              )}
            </div>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Odds
          </Text>
        ),
        meta: { width: "8%" },
      }),
      // this is to get the wagers for each book
      table3ColumnHelper.accessor("booksLabel", {
        cell: (info) => (
          <div>
            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_"
            >
              ${info.getValue<booksLabel>().book1.risk}
            </Text>
            <Text
              as="p"
              className="text-[14px] font-normal text-neutral-neutral_1100__day_"
            >
              ${info.getValue<booksLabel>().book2.risk}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Wager
          </Text>
        ),
        meta: { width: "8%" },
      }),

      table3ColumnHelper.accessor("booksLabel", {
        cell: (info) => (
          <div className="flex flex-col">
            <button
              className="flex w-[7rem] h-[1.5rem] font-poppins ml-2 hover:opacity-60 items-center"
              style={{
                borderRadius: "5px",
                fontSize: "11px",
                backgroundColor: "white",
                border: "2px solid transparent",
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(220deg, #5c8fff, #c14bff)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box",
                display: "flex",
                justifyContent: "space-between",
                padding: "0 2px 0 8px",
              }}
              onClick={() =>
                window.open(
                  updateState(
                    info.getValue<booksLabel>().book1.link,
                    userState,
                  ),
                  "_blank",
                )
              }
            >
              <span className="flex-grow text-center">Place Wager</span>
              <img
                alt=""
                src={`./Images/BooksIcon/${getBookIcon(info.getValue<booksLabel>().book1.name)}`}
                style={{ width: "20px", height: "100%" }}
              />
            </button>
            <button
              className="flex w-[7rem] h-[1.5rem] font-poppins ml-2 hover:opacity-60 items-center mt-2"
              style={{
                borderRadius: "5px",
                fontSize: "11px",
                backgroundColor: "white",
                border: "2px solid transparent",
                backgroundImage:
                  "linear-gradient(white, white), linear-gradient(220deg, #5c8fff, #c14bff)",
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box",
                display: "flex",
                justifyContent: "space-between",
                padding: "0 2px 0 8px",
              }}
              onClick={() =>
                window.open(
                  updateState(
                    info.getValue<booksLabel>().book2.link,
                    userState,
                  ),
                  "_blank",
                )
              }
            >
              <span className="flex-grow text-center">Place Wager</span>
              <img
                alt=""
                src={`./Images/BooksIcon/${getBookIcon(info.getValue<booksLabel>().book2.name)}`}
                style={{ width: "20px", height: "100%" }}
              />
            </button>
          </div>
        ),
        header: (info) => <Text as="p"></Text>,
        meta: { width: "10%" },
      }),
      table3ColumnHelper.accessor("payoutLabel", {
        cell: (info) => (
          <div>
            <Text as="p" className="text-[14px]  font-semibold text-green-600">
              ${info.getValue<any>()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Payout
          </Text>
        ),
        meta: { width: "8%" },
      }),
      table3ColumnHelper.accessor("profitLabel", {
        cell: (info) => (
          <Text as="p" className="text-[14px]  font-semibold text-green-600">
            ${info.getValue<any>()}
          </Text>
        ),
        header: (info) => (
          <Text
            as="p"
            className="pb-2.5 pt-0.5 text-left text-[14px] font-normal text-neutral-neutral_500__day_"
          >
            Profit
          </Text>
        ),
        meta: { width: "10%" },

        sortingFn: (a, b) => {
          const valueA =
            parseFloat(String(a.getValue("profitLabel")).replace("$", "")) || 0;
          const valueB =
            parseFloat(String(b.getValue("profitLabel")).replace("$", "")) || 0;
          return valueA - valueB;
        },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title> Sports Arbitrage Betting - Find the Best Odds</title>
        <meta
          name="description"
          content="Engage in sports arbitrage betting and ensure the best payouts with our comprehensive odds comparison from top bookmakers like MGM, FD, DK, and ESPN."
        />
      </Helmet>
      <div className="flex">
        <Sidebar1 />
        <div className="w-full bg-neutral-neutral_100__day_">
          <div>
            <div className="relative h-[90px] md:h-auto">
              <Header />
            </div>
            <div className="mr-7 flex items-start gap-[30px] md:mr-0">
              <div className="flex-1">
                <div className="flex flex-1 mr-7 ml-5 py-2 justify-center md:self-stretch md:px-5">
                  <Input
                    name="Search Bar"
                    placeholder="Enter your keyword..."
                    value={keywordOption}
                    onChange={(kw) => setKeywordOption(kw.target.value)}
                    prefix={
                      <Img
                        src="/Images/Dashboard-img/search.png"
                        alt="Contrast"
                        className="my-1 h-[100%] w-[100%]"
                      />
                    }
                    suffix={
                      keywordOption.length > 0 ? (
                        <CloseCircle onClick={() => setKeywordOption("")} />
                      ) : null
                    }
                    className="flex h-[48px] w-[18%] items-center justify-center gap-3 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[14px] text-blue_gray-300 md:w-full md:px-5"
                  />
                  <Input
                    name="Search Bar"
                    placeholder="Min Profit %"
                    className="flex h-[48px] w-[15%] ml-4 items-center justify-center gap-2 rounded-lg border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ px-3 text-[14px] text-blue_gray-300 md:w-full md:px-5"
                  />
                  <AntSelectBox
                    placeholder="Select leagues"
                    value={leagueOption}
                    onChange={(opt: React.SetStateAction<options[]>) =>
                      setLeagueOption(opt)
                    }
                    className="ml-3"
                  />

                  <AntSelectBox
                    placeholder="Select Bet Type"
                    value={marketOption}
                    options={marketOptions}
                    onChange={(opt: options[]) => setMarketOption(opt)}
                    className="ml-3  w-[15%]"
                  />
                  <AntSelectBox
                    placeholder="Select Books"
                    options={bookOptions}
                    onChange={(opt: options[]) => setBookOption(opt)}
                    value={bookOption}
                    className="ml-3  w-[15%]"
                  />

                  <div className="flex items-center">
                    <CustomSwitch
                      isActive={isLive}
                      activeColor="#B3261E"
                      onChange={setIsLive}
                      className="ml-3"
                    />
                    <span className="ml-1 text-[14px] font-normal text-red-700">
                      Live
                    </span>
                  </div>
                  <div className="flex items-center">
                    <CustomSwitch
                      isActive={isMiddles}
                      activeColor="#c14bff"
                      onChange={setIsMiddles}
                      className="ml-3"
                    />
                    <span className="ml-1 text-[14px] font-normal text-transparent bg-gradient bg-clip-text">
                      Middles
                    </span>
                  </div>
                </div>
                <div className="rounded-[16px] ml-5 border border-solid border-neutral-neutral_border__day_ bg-neutral-neutral_bg__day_ p-5">
                  <div className="flex ">
                    <p className=" text-left mr-2 text-[14px]  font-normal text-neutral-neutral_500__day_">
                      {" "}
                      Auto refresh:{countdown}{" "}
                    </p>
                    <button onClick={handleFetchArbitrage}>
                      {" "}
                      <Refresh className="w-[15px] h-[15px] " />
                    </button>
                  </div>
                  <ReactTable
                    size="xs"
                    bodyProps={{ className: "" }}
                    cellProps={{
                      className: "border-gray-300 border-b-2 border-solid",
                    }}
                    className="mb-2 md:block md:overflow-x-auto md:whitespace-nowrap"
                    columns={table3Columns}
                    data={filteredArbs}
                  />
                  {responseMessage && (
                    <div
                      className={`mt-4 p-3 rounded-lg ${
                        responseMessage.includes("Limited")
                          ? "bg-[#6B46C1] border-red-500"
                          : "bg-neutral-neutral_bg__day_ border-neutral-neutral_border__day_"
                      }`}
                    >
                      <div className="flex justify-between items-center">
                        <p
                          className="text-[14px] font-normal"
                          style={{ color: "white" }}
                        >
                          {responseMessage}
                        </p>
                        {responseMessage.includes("Limited") && (
                          <button
                            onClick={() => navigate("/PaymentOpt")}
                            className="ml-4 px-6 py-2.5 bg-gradient-to-r from-[#6B46C1] to-[#553C9A] text-white rounded-lg font-medium shadow-md transition-all duration-300 flex items-center gap-2 border border-[#6B46C1] hover:from-[#553C9A] hover:to-[#6B46C1] hover:shadow-lg"
                          >
                            <span
                              className="font-medium"
                              style={{ color: "white" }}
                            >
                              Upgrade Now
                            </span>
                            <svg
                              className="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 7l5 5m0 0l-5 5m5-5H6"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
