const termsText = `

<br>
Effective Date 01/12/2025
<br>
<br>
1. Introduction
<br>
Welcome to UP DOWN LEFT RIGHT LLC ("we," "us," or "our"). These Terms of Service ("Terms") govern your access to and use of our website, tools, and services. Along with our Privacy Policy, this document outlines your rights and responsibilities when interacting with our platform.
Please read these Terms carefully. They constitute a legally binding agreement between you and UP DOWN LEFT RIGHT LLC. If you disagree with any part of these Terms, you must discontinue use of our platform immediately. Your continued use indicates your acceptance of these Terms.
These Terms and our Privacy Policy ("Agreements") apply to all users, including visitors and registered members.
<br>
<br>
2. Communications
<br>
2.1 Consent to Communications
<br>
By creating an account with UP DOWN LEFT RIGHT LLC, you consent to receive communications from us, including:
Emails, such as newsletters, promotional materials, and service updates.
Text messages (SMS) to the phone number you provide during registration or account management.
Push notifications, if you have enabled them on your device or browser.
These communications may provide information about:
Your account activity or transactions.
Promotional offers, updates, or educational resources related to our services.

2.2 Opting Out
<br>
You may opt out of promotional communications at any time by:
Clicking the unsubscribe link in emails.
Replying STOP to text messages (SMS).
Adjusting your communication preferences in your account settings or contacting us at info@updownleftright.com.
Important Notice: Opting out of promotional communications will not affect essential communications, including:
Account-related notices.
Billing updates.
Security alerts or other critical notifications.
<br>
<br>
2.3 Compliance with Privacy Policy
<br>
All communications, including email and SMS, are governed by our Privacy Policy, which outlines how we collect, store, and use your information.
By providing your contact details, you confirm that:
You are authorized to receive communications on the provided email address or phone number.
You consent to the use of your personal information as described in the Privacy Policy.
<br>
<br>
3. Educational Purpose Only
<br>
All content, tools, and resources provided through our platform are intended for educational and informational purposes only. We do not provide personalized financial, legal, or betting advice. Decisions made based on our content are solely your responsibility.
<br>
<br>
3.1 No Personalized Advice
<br>
Our platform is designed to provide tools, resources, and information to assist you in making informed decisions regarding betting strategies and opportunities. However, we do not offer personalized betting advice tailored to your specific circumstances.
By using our platform, you acknowledge and agree to the following:
Generalized Information Only
The information, tools, and recommendations provided by our platform are general in nature and are not customized to your unique financial, personal, or betting circumstances.
No Endorsements
Any opportunities, strategies, or recommendations presented on our platform are not endorsements or guarantees of success.
The inclusion of such information is for educational purposes only and does not imply that any specific bet or strategy is appropriate for you.
Independent User Action
While we may collaborate with third-party services to simplify access to betting resources, we do not place bets on your behalf or act as an intermediary between you and any sportsbook.
Deciding whether to place a bet, and determining its appropriateness, is solely your responsibility.
Limitations of Our Knowledge
We cannot and do not account for all relevant details about your personal situation, including but not limited to financial condition, risk tolerance, or legal restrictions in your jurisdiction.
By using the platform, you agree to independently assess the suitability and risks of any betting decisions and will not rely solely on the information provided by our platform.
<br>
<br>
3.2 Responsibility for Your Own Financial and Betting Decisions
<br>
At UP DOWN LEFT RIGHT LLC, we firmly believe that you are the best person to manage your betting decisions. By using our platform, you agree to take full responsibility for all financial and betting actions taken based on the information, tools, or resources provided.
Acknowledgment of Risk
Betting involves inherent financial risks, and outcomes are uncertain.
You acknowledge that engaging in betting activities is a decision made solely at your discretion and risk.
No Liability for Outcomes
You agree that UP DOWN LEFT RIGHT LLC, its officers, employees, and agents shall not be held liable for:
Bets placed by you or others using your accounts.
Financial losses, legal disputes, or other consequences arising from your betting decisions.
Actions taken based on information, news, tools, or recommendations provided through our platform.
Verification and Compliance
You are solely responsible for verifying the legality of your betting activities and ensuring compliance with all applicable laws and regulations in your jurisdiction.
You agree to review and adhere to the terms and conditions of any sportsbook or betting platform you use in conjunction with our tools.
Acceptance of Accountability
By using our platform, you acknowledge that any financial or betting decisions made are fully your responsibility.
The platform is a resource for educational purposes only and does not guarantee specific outcomes.
You expressly acknowledge and agree that UP DOWN LEFT RIGHT LLC shall not be held responsible or liable for any financial losses, damages, or other consequences resulting from your use of the platform, including reliance on its content, tools, or resources.
<br><br> 
4. Purchases
<br>
4.1 Payment Information
When purchasing any product or service ("Purchase") through our platform, you may be required to provide accurate and complete payment details, including but not limited to:
Credit card information.
Billing and shipping addresses.
<br>
4.2 Confirmation of Authorization
By completing a Purchase, you confirm that:
You are authorized to use the payment method provided.
The information you submit is accurate, complete, and up to date.
<br>
4.3 Use of Third-Party Providers
Purchases are processed through third-party providers, as outlined in our Privacy Policy.
By completing a Purchase, you authorize us to share your payment and transaction details with these providers for processing purposes.
<br>
4.4 Right to Refuse or Cancel Orders
We reserve the right to refuse or cancel orders at our discretion, including but not limited to the following reasons:
Product or service unavailability.
Errors in pricing or product descriptions.
Suspected fraudulent, unauthorized, or illegal activity.
If an order is canceled, we will notify you and issue a refund where applicable.
<br>
<br>
5. Subscriptions
5.1 Subscription Services
Certain services are offered on a subscription basis ("Subscription(s)").
Subscriptions are billed in advance on a recurring schedule ("Billing Cycle"), such as:
Monthly.
Annually.
<br>
5.2 Automatic Renewal
Subscriptions will renew automatically under the same terms at the end of each Billing Cycle unless canceled by:
You, through your account settings or by contacting us.
Us, at our discretion.
Renewal charges will be billed to the payment method on file.
<br>
5.3 Managing or Canceling Subscriptions
You may manage or cancel your Subscription renewal at any time by:
Accessing your account settings.
Contacting us at info@updownleftright.com for assistance.
To avoid charges for the next Billing Cycle, cancellations must be made before the current cycle ends.
<br>
5.4 No Refunds for Early Termination
Unless required by applicable law, no refunds will be issued for partial Billing Cycles or unused portions of a Subscription after cancellation.
<br>
<br>
6. Payment Information
<br>
6.1 Valid Payment Details
To maintain an active subscription, you must provide valid and current payment information, including but not limited to:
Credit card details.
Billing address.
Any other required payment method details.
<br>
6.2 Authorization for Charges
By submitting your payment information, you authorize us to charge your selected payment method for all applicable Subscription fees, including:
Recurring subscription charges based on your selected plan.
Applicable taxes and other fees, if any.
<br>
6.3 Payment Failures
If payment is unsuccessful due to insufficient funds, expired payment details, or other issues:
We may suspend or terminate your subscription immediately.
You will receive a notice requiring manual payment to reactivate your account.
<br>
6.4 User Responsibility
You are responsible for ensuring that your payment details remain valid and up to date.
Failure to update your payment information may result in interruption or termination of your subscription.
<br>
<br>
7. Fee Changes
<br>
7.1 Right to Modify Fees
We reserve the right to modify subscription fees or other charges associated with the Service at our sole discretion.
<br>
7.2 Notice of Fee Changes
Advance notice of fee changes will be provided through:
Email communication to the address associated with your account, or
A notification within the platform.
The new fees will take effect at the end of your current Billing Cycle, unless otherwise specified.
<br>
7.3 Acceptance of Fee Changes
Your continued use of the Service after the adjusted fees take effect constitutes your acceptance of the new rates.
If you do not agree with the fee adjustments, you must cancel your subscription before the start of the next Billing Cycle to avoid incurring the new charges.
<br>
<br>
8. Free Trials
<br>
8.1 Availability of Free Trials
Free trials may be offered at our sole discretion and for limited periods as determined by us.
We reserve the right to modify, suspend, or discontinue free trial offers at any time without prior notice.
<br>
8.2 Billing During Free Trials
If billing details are required during the free trial sign-up, your payment method will not be charged until the trial period concludes.
Upon the trial's expiration, unless you cancel your subscription prior to the trial's end, your payment method will automatically be charged for the applicable subscription fee.
<br>
8.3 Restrictions on Free Trials
Multiple sign-ups for free trials using different accounts, identities, or methods to circumvent restrictions are strictly prohibited.
We reserve the right to terminate or refuse free trials for users who violate this provision.
<br>
8.4 Cancellation During Free Trials
You may cancel your free trial at any time before it ends to avoid incurring subscription fees.
Instructions for cancellation will be provided during the trial sign-up process or within your account settings.
<br>
8.5 Modification or Termination
We reserve the right to:
Modify the terms or duration of free trials.
Revoke access to free trials for users who violate these Terms.
Discontinue free trial offerings entirely without prior notice.
<br>
<br>
9. Refunds
<br>
9.1 General Policy
Except where required by applicable law, all subscription fees and purchases made through the platform are final and non-refundable.
<br>
9.2 Exceptions
Refunds may only be provided in the following situations, at our sole discretion:
If a technical error on our part prevents access to a purchased service or subscription.
If a billing error occurs, such as duplicate charges.
<br>
9.3 No Refunds for Change of Mind
Refunds will not be provided for:
Changes in your circumstances or decision to discontinue use of the Service.
Dissatisfaction with features, unless a material breach of these Terms is determined on our part.
<br>
9.4 Legal Compliance
If applicable law requires refunds in certain circumstances, we will comply with those obligations.
For users residing in jurisdictions with mandatory refund laws, these laws will take precedence over this clause to the extent they apply.
<br>
<br>
10. Contests, Sweepstakes, and Promotions
<br>
10.1 General Terms
From time to time, we may host contests, sweepstakes, or other promotions (collectively, “Promotions”) on our platform.
Each Promotion will be governed by its own set of specific terms, conditions, and rules (“Promotion Rules”).
<br>
10.2 Conflicts with Terms
If there is a conflict between these Terms and the Promotion Rules, the Promotion-specific rules will take precedence.
<br>
10.3 Eligibility
Participation in Promotions may be subject to eligibility requirements, such as:
Age restrictions.
Residency in certain geographic locations.
Compliance with applicable laws and regulations.
You are responsible for ensuring that you meet the eligibility criteria outlined in the Promotion Rules before participating.
<br>
10.4 Notification of Winners
Winners of Promotions will be notified in accordance with the specific Promotion Rules.
We reserve the right to disqualify participants or winners who violate these Terms or the Promotion Rules.
<br>
10.5 Taxes and Legal Obligations
Any applicable taxes, fees, or other legal obligations associated with receiving prizes from Promotions are the sole responsibility of the winner.
<br>
<br>
11. User Content
Our platform may allow users to post, store, and share content (“Content”), including text, images, and other materials. By uploading or sharing Content, you agree to the following terms:
<br>
11.1 User Rights and Responsibilities
You affirm that you own the rights to the Content or have obtained all necessary permissions to post, store, or share it.
You affirm that your Content:
Complies with all applicable local, state, national, and international laws.
Does not violate the rights of any third party, including intellectual property rights, privacy rights, or rights of publicity.
<br>
11.2 License Grant to the Platform
By posting or sharing Content, you grant UP DOWN LEFT RIGHT LLC a non-exclusive, royalty-free, worldwide license to:
Use, reproduce, modify, adapt, publish, translate, distribute, and display the Content in connection with the operation and promotion of the Service.
Make the Content accessible to other users under these Terms.
<br>
11.3 Platform’s Rights Regarding User Content
We retain the right, but are not obligated, to:
Monitor, edit, or remove user Content that we determine, in our sole discretion, violates our policies, these Terms, or any applicable law.
Remove or disable access to Content that may infringe on the intellectual property rights of others.
<br>
11.4 User Accountability
You acknowledge and agree that you are solely responsible for any Content you upload or share on the platform.
You agree to indemnify and hold harmless UP DOWN LEFT RIGHT LLC for any claims arising from your Content, including claims of infringement, defamation, or violations of applicable law.
<br>
<br>
12. Prohibited Uses
You may use the Service only for lawful purposes and in full compliance with these Terms. You agree that you will not, under any circumstances, use the Service for any of the following:
<br>
12.1 To Violate Laws or Regulations
In any manner that violates applicable local, state, national, or international laws, regulations, or treaties.
This includes, but is not limited to, violations of intellectual property laws, privacy laws, and laws governing online conduct.
<br>
12.2 To Exploit or Harm Minors
To harm, attempt to harm, exploit, or endanger minors in any way.
This includes exposing minors to inappropriate content or soliciting their personal information.
<br>
12.3 To Engage in Unsolicited Communications
To transmit, facilitate, or engage in the sending of unauthorized advertising or promotional material.
Prohibited activities include:
Spam.
Junk mail.
Chain letters or similar solicitations.
<br>
12.4 To Impersonate Others
To impersonate or attempt to impersonate:
The Company or its employees.
Other users or individuals.
Any entity, including by creating false accounts or misrepresenting your affiliation.
<br>
12.5 To Infringe on Rights
In any manner that infringes upon the rights of others, including but not limited to:
Intellectual property rights.
Privacy rights.
Rights of publicity.
<br>
12.6 To Conduct Fraudulent or Harmful Activities
To engage in deceptive, fraudulent, or harmful activities, including but not limited to:
Phishing.
Scamming.
Disseminating false or misleading information.
<br>
12.7 To Interfere with Others’ Use of the Service
To engage in behavior that restricts, disrupts, or inhibits others from accessing or enjoying the Service.
To engage in behavior that may harm or offend the Company or other users, as determined by us in our sole discretion.
<br>
12.8 To Access or Exploit the Service Improperly
To access, monitor, or copy any part of the Service using automated tools (e.g., robots, spiders, or scrapers) or manual processes without our explicit prior written consent.
To exploit the Service for unauthorized commercial purposes, including but not limited to:
Reselling.
Sublicensing.
Redistributing content or tools.
<br>
<br>
13. Technical and Operational Misuse
You agree not to engage in any activities that compromise the technical integrity or operation of the Service. Prohibited activities include, but are not limited to:
<br>
13.1 Overloading the Service
Using the Service in any way that could disable, overburden, damage, or impair its functionality.
Prohibited actions include:
Initiating excessive requests.
Engaging in activities that consume disproportionate resources or disrupt performance.
<br>
13.2 Interference with Operation
Using devices, software, or routines that interfere with the proper operation of the Service.
This includes bypassing or attempting to bypass security measures or access restrictions.
<br>
13.3 Introduction of Malicious Software
Uploading, transmitting, or introducing malicious materials, such as:
Viruses, worms, Trojan horses, spyware, or other harmful code.
These activities are strictly prohibited and designed to disrupt, damage, or limit the functionality of the Service or connected systems.
<br>
13.4 Unauthorized Access
Attempting to gain unauthorized access to any part of the Service, including but not limited to:
User accounts, databases, servers, or systems connected to the Service.
<br>
13.5 Launching Attacks
Conducting or facilitating any denial-of-service (DoS) or distributed denial-of-service (DDoS) attacks.
These actions are strictly prohibited and intended to disrupt the availability of the Service or connected networks.
<br>
13.6 Manipulation of Content
Altering, removing, or tampering with content or materials available on the Service without explicit authorization.
<br>
<br>
14. Misuse of Intellectual Property and Competitive Use
<br>
14.1 Unauthorized Commercial Use
You are prohibited from using the Service or its content to compete with us or engage in any revenue-generating activity without our express prior written consent.
Prohibited activities include, but are not limited to:
Scraping data from the platform.
Reverse engineering proprietary tools, features, or functionality.
<br>
14.2 Intellectual Property Violations
You may not misuse, copy, modify, distribute, or otherwise exploit any content, trademarks, trade dress, or proprietary information available through the Service without proper authorization.
Unauthorized use of our intellectual property may result in:
Suspension or termination of your account.
Legal action, including claims for damages.
<br>
<br>
15. Consequences of Prohibited Use
<br>
15.1 Right to Investigate
We reserve the right to investigate any suspected violations of these Terms or prohibited uses of the platform.
Investigations may include, but are not limited to, reviewing user activity, data logs, and other relevant information.
<br>
15.2 Legal and Disciplinary Actions
If a violation is confirmed, we may take appropriate legal or disciplinary action, including but not limited to:
Suspending or Terminating Access: Temporarily or permanently suspending or terminating your access to the Service.
Reporting to Authorities: Reporting violations to law enforcement, regulatory authorities, or other relevant bodies.
Pursuing Remedies: Initiating civil or criminal proceedings, as appropriate, to seek remedies or damages.
<br>
15.3 User Acknowledgment and Responsibility
By using the Service, you agree to comply fully with this section and all applicable laws and regulations.
You acknowledge that any violation of the prohibited uses outlined in these Terms may expose you to significant liabilities, including:
Legal penalties.
Financial damages.
Reputational harm.
<br>
<br>
16. No Use by Minors
<br>
16.1 Age Requirement
Our platform is intended solely for individuals aged 18 or older.
By accessing or using the platform, you affirm that you meet this age requirement.
<br>
16.2 Prohibited Access
If you are under the age of 18, you are strictly prohibited from accessing, registering for, or using any features of the platform.
<br>
16.3 Responsibility for Compliance
It is your responsibility to ensure compliance with this age restriction.
If we become aware that a user under the age of 18 has accessed the platform, we reserve the right to terminate their account and restrict further access.
<br>
<br>
17. Intellectual Property
<br>
17.1 Ownership of Materials
All materials available on the platform, including but not limited to proprietary content, features, graphics, logos, text, software, and other intellectual property (collectively, "Platform Materials"), are owned by or licensed to UP DOWN LEFT RIGHT LLC.
These Platform Materials are protected by copyright, trademark, trade secret, and other intellectual property laws both in the United States and internationally.
<br>
17.2 Prohibited Use
You may not reproduce, modify, copy, distribute, publicly display, perform, republish, download, store, transmit, or create derivative works of any Platform Materials without prior written permission from UP DOWN LEFT RIGHT LLC.
Unauthorized use of Platform Materials is strictly prohibited and may result in legal action.
<br>
17.3 Trademarks
All trademarks, service marks, and trade names displayed on the platform are the property of UP DOWN LEFT RIGHT LLC or their respective owners.
Use of these marks without prior written consent is prohibited.
<br>
17.4 Limited License to Users
Subject to compliance with these Terms, you are granted a limited, non-exclusive, non-transferable, and revocable license to access and use the platform and its materials for personal, non-commercial purposes only.
This license does not grant you ownership rights to any intellectual property.
<br>
17.5 Reporting Infringement
If you believe that any content on the platform infringes your intellectual property rights, please notify us at info@updownleftright.com with the following details:
A description of the intellectual property allegedly infringed.
The specific location of the infringing material (e.g., URL or specific page).
Your contact information and a statement that you have a good-faith belief that the use of the material is unauthorized.
<br>
<br>
18. Analytics
<br>
18.1 Use of Google Analytics
Our Service uses Google Analytics, a web analytics service provided by Google, to track and report website traffic.
The data collected by Google helps us understand user activity, improve the performance of our platform, and optimize the user experience.
<br>
18.2 Data Sharing with Google
The information collected by Google Analytics may also be shared with other Google services.
Google may use this data to personalize ads within its advertising network, in accordance with its own privacy policies.
<br>
18.3 Privacy and Data Protection
For more information about how Google collects and uses data, please review:
Google Privacy Policy
Google Analytics Data Protection Overview
<br>
18.4 Your Control Over Data
You can opt out of Google Analytics tracking by installing the Google Analytics Opt-Out Browser Add-On, available at https://tools.google.com/dlpage/gaoptout.
<br>
<br>
19. Indemnification
<br>
19.1 User Obligations
You agree to indemnify, defend, and hold harmless UP DOWN LEFT RIGHT LLC, its officers, directors, employees, agents, affiliates, and licensors (collectively, the "Indemnified Parties") from and against any and all claims, demands, liabilities, damages, losses, costs, and expenses (including reasonable attorneys' fees) arising out of or related to:
Your use or misuse of the platform.
Your breach or violation of these Terms.
Your violation of any applicable laws, regulations, or third-party rights, including intellectual property rights.
Any content you submit, post, or transmit through the platform.
<br>
19.2 Scope of Indemnification
This indemnification obligation applies to any claims brought by third parties, including but not limited to:
Claims for defamation, infringement of intellectual property rights, or violation of privacy or publicity rights.
Claims arising from your fraudulent, negligent, or unlawful activities.
<br>
19.3 Cooperation
UP DOWN LEFT RIGHT LLC reserves the right to assume the exclusive defense and control of any matter subject to indemnification under this clause.
You agree to cooperate fully with our defense of such claims and not to settle any matter without our prior written consent.
<br>
19.4 No Limitation of Liability
Your indemnification obligations under this clause do not limit any other rights or remedies available to UP DOWN LEFT RIGHT LLC under applicable law or equity.
<br>
<br>
20. Arbitration Agreement
<br>
20.1 Binding Arbitration
Any disputes, claims, or controversies arising out of or relating to these Terms, the platform, or your use of the Service shall be resolved exclusively through binding arbitration.
Arbitration will be conducted in accordance with the rules of the American Arbitration Association (AAA).
<br>
20.2 Location and Governing Law
Arbitration will take place in the State of New Jersey.
The proceedings will be governed by the laws of the State of New Jersey, without regard to conflict of law principles.
<br>
20.3 Arbitrator’s Authority
The arbitrator shall have the exclusive authority to resolve any disputes regarding the:
Interpretation of this Arbitration Agreement.
Applicability, enforceability, or formation of this Arbitration Agreement.
Claim that all or part of this Agreement is void or voidable.
<br>
20.4 Judgment on Award
The arbitration award shall be final and binding.
Judgment on the award may be entered in any court of competent jurisdiction.
<br>
20.5 Class Action Waiver
You agree that any arbitration will be conducted on an individual basis only.
You expressly waive the right to participate in any class, collective, or representative action.
<br>
20.6 Exceptions to Arbitration
This Arbitration Agreement does not preclude either party from seeking injunctive relief or other equitable remedies in a court of law for alleged infringement of intellectual property rights.
<br>
20.7 Costs and Fees
Each party shall bear its own costs and expenses associated with arbitration.
Exceptions may apply as provided under applicable arbitration rules or as ordered by the arbitrator.
<br>
20.8 Opt-Out Option
You may opt out of this Arbitration Agreement by providing written notice to info@updownleftright.com within 30 days of first agreeing to these Terms.
If you do not opt out, this Arbitration Agreement will be binding.
<br>
<br>
21. Limitation of Liability
To the fullest extent permitted by applicable law:
<br>
21.1 No Indirect, Incidental, or Consequential Damages
UP DOWN LEFT RIGHT LLC, its affiliates, officers, employees, agents, and licensors shall not be liable for any indirect, incidental, special, consequential, or punitive damages.
This includes, but is not limited to, damages for:
Loss of profits, revenue, data, or use.
Business interruption or loss of goodwill.
Other intangible losses arising from or related to your use of or inability to use the platform.
<br>
21.2 Direct Damages Limited to Fees Paid
Our total liability for any claim, whether in contract, tort (including negligence), or otherwise, arising from or related to your use of the platform shall not exceed the total amount you paid to UP DOWN LEFT RIGHT LLC in the 12 months immediately preceding the claim.
<br>
21.3 Exclusions of Certain Liabilities
We are not liable for damages resulting from:
Errors, omissions, or inaccuracies in content, tools, or resources.
Unauthorized access to or alteration of your data.
Interruptions, delays, or failures of the platform caused by factors beyond our reasonable control, including third-party actions.
<br>
21.4 Jurisdictional Exceptions
Some jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages.
In such jurisdictions, our liability is limited to the fullest extent permitted by law.
<br>
<br>

22. Disclaimer of Warranties
The platform provides "as is" without warranties of any kind, whether express or implied. We do not guarantee uninterrupted or error-free service.
23. Governing Law
These Terms shall be governed by and construed in accordance with the laws of the State of New Jersey, without regard to its conflict of law principles.
<br>
23.1 No Waiver of Rights
The failure of UP DOWN LEFT RIGHT LLC to enforce any provision or exercise any right under these Terms shall not constitute a waiver of such provision or right.
Any waiver must be in writing and signed by an authorized representative of UP DOWN LEFT RIGHT LLC.
<br>
23.2 Severability
If any provision of these Terms is found to be invalid, illegal, or unenforceable by a court of competent jurisdiction, that provision shall be limited or eliminated to the minimum extent necessary.
The remaining provisions of these Terms shall remain in full force and effect.
<br>
23.3 Entire Agreement
These Terms, along with our Privacy Policy and any other legal notices published on our platform, represent the entire agreement between you and UP DOWN LEFT RIGHT LLC regarding your use of the Service.
They supersede all prior agreements, communications, and understandings, whether oral or written, regarding the Service.
<br>
23.4 Jurisdiction
You agree that any disputes arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the courts located in New Jersey.
You waive any objection to such jurisdiction or venue.

<br>
<br>
24. Copyright Policy
We respect the intellectual property rights of others and are committed to complying with applicable copyright laws. If you believe that content available on our platform infringes your copyright, you may submit a notification of infringement under the Digital Millennium Copyright Act (DMCA) or other applicable laws.
<br>
24.1 Reporting Copyright Infringement
To file a copyright infringement claim, please provide the following information in writing to our designated Copyright Agent:
A physical or electronic signature of the person authorized to act on behalf of the copyright owner.
Identification of the copyrighted work claimed to have been infringed, including its location (e.g., URL or other description).
Identification of the material that you claim is infringing or subject to infringing activity, along with its location on the platform.
Your contact information, including your name, address, phone number, and email address.
A statement that you have a good-faith belief that the use of the material in question is not authorized by the copyright owner, its agent, or the law.
A statement, made under penalty of perjury, that the information provided in your notice is accurate and that you are authorized to act on behalf of the copyright owner.
Send your notification to our Copyright Agent at:
Email: info@updownleftright.com
<br>
24.2 Repeat Infringer Policy
We reserve the right to terminate user accounts or restrict access to our platform for users who are found to repeatedly infringe upon the intellectual property rights of others.
<br>
24.3 Counter-Notification Procedure
If you believe that the material you posted on our platform was mistakenly identified as infringing, you may submit a counter-notification to our Copyright Agent with the following details:
Your physical or electronic signature.
Identification of the material removed or disabled and its location before removal.
A statement, under penalty of perjury, that you have a good-faith belief the material was removed or disabled due to mistake or misidentification.
Your contact information, including your name, address, phone number, and email address.
A statement that you consent to the jurisdiction of the federal court in your district (or where your address is located) and that you will accept service of process from the person who filed the original copyright infringement claim or their agent.
Upon receipt of a valid counter-notification, we may reinstate the material in question, unless the original claimant files a lawsuit within 10 business days.
<br>
<br>
25. Changes to the Service
<br>
25.1 Right to Modify or Discontinue
We reserve the right to modify, suspend, withdraw, or discontinue any part of the Service, including features, content, or materials provided via the platform, at our sole discretion and without prior notice.
<br>
25.2 No Liability for Unavailability
We are not liable for any unavailability of the Service, whether temporary or permanent.
This includes interruptions caused by maintenance, upgrades, technical issues, or changes to our business operations.
<br>
25.3 Access Restrictions
From time to time, we may restrict access to portions of the Service or the entire platform.
This may apply to certain users, including registered account holders, to:
Ensure proper operation.
Perform updates.
Comply with legal obligations.
Address other reasons at our sole discretion.
<br>
25.4 User Responsibility
You acknowledge that it is your responsibility to ensure that your use of the Service accommodates potential interruptions or changes.
We are not liable for any inconvenience, loss, or damages resulting from such modifications or restrictions.
<br>
25.5 Communication of Changes
While we may provide notice of significant changes to the Service, we are not obligated to do so.
Continued use of the platform following changes constitutes your acceptance of those changes.
<br>
<br>
26. Accounts
By creating an account on our platform, you acknowledge and agree to the following terms:
26.1 Eligibility and Accurate Information
You confirm that you are at least 18 years of age.
All information you provide during account registration, or any updates thereafter, must be accurate, complete, and current.
Providing false, incomplete, or outdated information may result in the immediate suspension or termination of your account.
<br>
26.2 Account Security and Responsibility
You are solely responsible for maintaining the confidentiality of your account credentials, including your username and password.
You agree to take reasonable steps to secure access to your account, including:
Limiting access to your computer, device, or any platform used to access your account.
Logging out when accessing your account on shared or public devices.
All activities conducted under your account are your responsibility, whether authorized by you or not.
<br>
26.3 Unauthorized Access
If you become aware of unauthorized use of your account, loss of credentials, or other security breaches, you must notify us immediately at info@updownleftright.com.
Failure to report security issues promptly may result in additional liabilities.
<br>
26.4 Prohibited Usernames
Your chosen username must not:
Impersonate another person or entity.
Infringe upon the intellectual property or other rights of any individual or organization.
Contain offensive, vulgar, obscene, or otherwise inappropriate language.
<br>
26.5 Service and Content Management
We reserve the right, at our sole discretion, to:
Refuse service or restrict access to any user.
Suspend or terminate accounts that violate these Terms or our policies.
Remove or edit user-generated content determined to be inappropriate or in violation of these Terms.
Cancel orders or transactions suspected of fraudulent or unauthorized activity.
<br>
26.6 Non-Transferability
Your account is personal to you and may not be:
Transferred.
Assigned.
Sold to another party under any circumstances.
<br>
By registering for and maintaining an account with us, you agree to abide by these requirements and accept full accountability for your actions on the platform.
<br>
<br>
27. Confidentiality
We may provide you with access to proprietary or sensitive information that is not publicly available, which we refer to as Confidential Information. This includes, but is not limited to:
Proprietary tools, data, or resources provided as part of our Services or subscriptions.
Materials, discussions, or insights related to the operation of UP DOWN LEFT RIGHT LLC.
Any data or data feeds delivered as part of your Subscription (“Service Data”).
<br>


27.1 Exclusions from Confidential Information
Confidential Information does not include information that:
Becomes publicly available through no fault or breach of these Terms by you.
Is lawfully obtained by you from a third party not under any obligation of confidentiality.
Was already in your possession prior to disclosure by us.
Is independently developed by you without reference to or use of our Confidential Information.
<br>
27.2 Obligations Regarding Confidential Information
You agree to:
Protect Confidential Information
Safeguard our Confidential Information with the same level of care you use to protect your own sensitive information, but no less than a commercially reasonable standard.
Limit Use
Use Confidential Information only to perform your obligations or exercise your rights under these Terms.
Restrict Disclosure
Not disclose Confidential Information to any third party without our prior written authorization.
If required by law or legal process to disclose Confidential Information, you agree to notify us promptly (if legally permitted) to allow us to seek a protective order or other remedy at our expense.
<br>
27.3 User Representations
You further agree and warrant that:
You will not publish, share, or distribute the Service or any Service Data obtained through the platform.
You will not allow unauthorized access to the Service or Service Data.
You will use the Service and Service Data in full compliance with applicable laws and regulations.
You will obtain all necessary licenses, permits, or approvals required to access or use our Services.
<br>
27.4 Exceptions for Regulatory Authorities
Notwithstanding the above, we reserve the right to disclose these Terms or related agreements to gaming authorities or regulatory bodies as required by law.
<br>
<br>
28. Links to Third-Party Websites and Services
Our platform may contain links to third-party websites or services that are not owned, operated, or controlled by UP DOWN LEFT RIGHT LLC. These links are provided for your convenience and informational purposes only.
<br>
28.1 No Control or Responsibility
We do not control and are not responsible for:
The content, privacy policies, terms of service, or practices of any third-party websites or services.
The inclusion of links on our platform, which does not constitute or imply endorsement, sponsorship, or approval of the third-party websites, their content, or their operators.
<br>
28.2 User Acknowledgment of Risk
By using our platform and accessing third-party websites or services, you acknowledge and agree that:
Any interaction with third-party websites or services, including but not limited to purchasing goods or services, is solely at your own risk and discretion.
UP DOWN LEFT RIGHT LLC is not responsible or liable, directly or indirectly, for:
Damages or losses caused by or alleged to be caused by your use of or reliance on third-party websites or their content.
Disruptions, inaccuracies, or harmful effects resulting from third-party interactions.
<br>
28.3 No Guarantees or Endorsements
We do not guarantee or make any representations about:
The quality, safety, legality, or accuracy of any goods, services, or information provided by third-party websites.
The suitability of third-party services for your purposes.
It is your responsibility to verify the legitimacy and reliability of any third-party services before engaging with them.
<br>
28.4 Review Third-Party Terms and Policies
We strongly encourage you to:
Review the terms of service and privacy policies of any third-party websites or services you choose to visit.
Understand that their policies and practices may differ significantly from ours, and it is your responsibility to familiarize yourself with their terms.
<br>
<br>
29. Force Majeure
UP DOWN LEFT RIGHT LLC shall not be held liable or responsible for any failure or delay in performance caused by events or circumstances beyond our reasonable control. These events, referred to as "Force Majeure Events," include but are not limited to:
<br>
29.1 Examples of Force Majeure Events
Acts of Nature
Natural disasters such as earthquakes, floods, hurricanes, tornadoes, or other extreme weather conditions.
Government Actions
Changes in laws or regulations, acts of government, embargoes, or other actions imposed by regulatory authorities.
Public Health Crises
Epidemics, pandemics, or outbreaks of disease, including government-mandated lockdowns or restrictions.
Technical or Infrastructure Failures
Power outages, telecommunications failures, internet service disruptions, or interruptions caused by cyberattacks.
Labor Disputes or Unrest
Strikes, lockouts, or other labor-related disruptions that affect service availability.
Acts of War or Terrorism
War, armed conflicts, acts of terrorism, civil unrest, or other security-related incidents.
<br>
29.2 Implications of Force Majeure
Suspension of Obligations
During the occurrence of a Force Majeure Event, our obligations under these Terms may be suspended for the duration of the event.
Efforts to Mitigate
We will make reasonable efforts to mitigate the impact of the Force Majeure Event and resume normal operations as soon as practicable.
User Acknowledgment
You acknowledge and agree that we are not liable for any damages, losses, or disruptions resulting from Force Majeure Events.
<br>
<br>
30. Termination
<br>
30.1 Right to Terminate
We reserve the right to suspend, restrict, or terminate your account or access to the Service at any time, at our sole discretion, with or without notice.
Reasons for termination may include, but are not limited to:
Violations of these Terms or our policies.
Fraudulent, illegal, or unauthorized activity.
Actions that harm or could potentially harm the Service, our reputation, or other users.
Failure to comply with payment obligations, if applicable.
<br>
30.2 Effect of Termination
Upon termination of your account:
All rights granted to you under these Terms will immediately cease.
You must stop using the Service and delete or remove any proprietary materials obtained from the platform.
Any outstanding payments or obligations owed by you will remain due and enforceable.
<br>
30.3 No Liability for Termination
UP DOWN LEFT RIGHT LLC shall not be liable for any damages, losses, or inconveniences caused by the suspension or termination of your account, except where required by law.
<br>
30.4 User-Initiated Termination
You may terminate your account at any time by discontinuing use of the Service and, if applicable, canceling your subscription.
<br>
30.5 Surviving Provisions
Any provisions of these Terms that by their nature should survive termination will remain in effect.
This includes, but is not limited to, provisions regarding:
<br>
Intellectual property.
<br>
Disclaimers.
<br>
Indemnification.
<br>
Limitations of liability. 
<br>
<br>
31. Geographic and State-Level Restrictions
<br>
31.1 United States Use Only
Our platform and services are intended solely for users located within the United States, in states where access to and use of sports betting-related tools and resources are legally permitted.
By accessing or using the platform, you confirm that you are physically located in a state where such activities are lawful.
<br>
31.2 State-Level Compliance
Sports betting and related activities, including the use of tools or services that provide betting information, are regulated at the state level in the United States.
It is your responsibility to ensure that your use of our platform complies with all applicable state laws and regulations.
<br>
31.3 No Representation of Legality
We make no representations or warranties that the platform, its content, or its services are legal or appropriate for use in every state.
Accessing or using the platform in violation of applicable state laws is strictly prohibited and may result in the termination of your account.
<br>
31.4 Disclaimer for Restricted States
If you are located in a state where sports betting or the use of betting-related tools is restricted or prohibited, you may not use our platform.
<br>
31.5 International Use
We make no representations or warranties that the platform is appropriate or lawful for use outside the United States.
Accessing the platform from other jurisdictions is done at your own risk, and you are responsible for compliance with all local laws.
<br>
<br>
32. Waiver and Severability
<br>
32.1 Waiver of Rights
No waiver by UP DOWN LEFT RIGHT LLC of any provision or right under these Terms shall be deemed a waiver of any other provision or right, nor shall it be deemed a continuing waiver.
Failure to enforce or exercise any provision or right under these Terms shall not constitute a waiver of that provision or right.
Any waiver must be in writing and signed by an authorized representative of UP DOWN LEFT RIGHT LLC.
<br>
32.2 Severability of Provisions
If any provision of these Terms is determined by a court or tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, that provision shall be deemed severed or limited to the minimum extent necessary to comply with the law.
The remaining provisions of these Terms shall remain in full force and effect and continue to be enforceable.
<br>
32.3 Preservation of Intent
If any invalid or unenforceable provision would become valid or enforceable by limiting or modifying it, the provision shall be deemed to have been written, construed, and enforced as so limited or modified to preserve its original intent to the fullest extent permitted by law.
<br>
<br>
33. Amendments to Terms
<br>
33.1 Right to Modify Terms
We reserve the right to update, revise, or amend these Terms at any time at our sole discretion.
Any changes will be effective upon posting the updated version on this page unless a later effective date is specified.
<br>
33.2 Notification of Changes
While we may provide notice of significant changes to these Terms, it is your responsibility to review them periodically to stay informed of any updates.
<br>
33.3 Acceptance of Revised Terms
By continuing to access or use the Service after the updated Terms become effective, you agree to be bound by the revised Terms.
If you do not agree with the updated Terms, you must discontinue your use of the Service immediately.
<br>
33.4 Entire Agreement
These Terms, as amended from time to time, represent the entire agreement between you and UP DOWN LEFT RIGHT LLC regarding your use of the Service.
They supersede all prior agreements or understandings, whether written or oral.
<br>
<br>
34. Acknowledgment
BY ACCESSING OR USING THE SERVICE OR ANY SERVICES PROVIDED BY UP DOWN LEFT RIGHT LLC, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE BOUND BY THESE TERMS OF SERVICE, INCLUDING ANY UPDATES OR MODIFICATIONS POSTED FROM TIME TO TIME.
<br>
34.1 Binding Agreement
Your use of the platform constitutes a legally binding agreement between you and UP DOWN LEFT RIGHT LLC.
<br>
34.2 Consent to Additional Policies
You further agree to comply with any additional terms, policies, or rules referenced in these Terms, including our Privacy Policy, as part of your use of the Service.
<br>
34.3 User Responsibility
By continuing to use the Service, you acknowledge that you are responsible for periodically reviewing these Terms.
You agree to ensure compliance with the provisions outlined in these Terms and any updates made to them.

<br>
<br>



35. Contact Us
<br>
<br>
For any feedback, comments, or technical support inquiries, please contact us:
<br>
By email: info@updownleftright.com
<br>        
By visiting our website: updownleftright.com

`;

const TermsAndConditions = () => (
    <div dangerouslySetInnerHTML={{ __html: termsText }} />
  );
  
  export default TermsAndConditions;