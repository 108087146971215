import { Img, Heading } from "../../components/HomeCom";
import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from "react-accessible-accordion";

const accordionData = [
  {
    questionNumber: "Q 1",
    questionSeparator: ":",
    questionContent: "What is sports arbitrage betting and how does it work?"
  },
  {
    questionNumber: "Q 2",
    questionSeparator: ":",
    questionContent: "What sports and leagues does UpDownLeftRight.io cover?"
  },
  {
    questionNumber: "Q 3",
    questionSeparator: ":",
    questionContent: "How can I find positive EV bets on your platform?"
  },
  {
    questionNumber: "Q 4",
    questionSeparator: ":",
    questionContent: "Do I need accounts with multiple sportsbooks?"
  },
  {
    questionNumber: "Q 5",
    questionSeparator: ":",
    questionContent: "How much does the UpDownLeftRight.io service cost?"
  }
];

// FAQ answers for each question
const faqAnswers = [
  {
    intro: "Sports arbitrage is a risk-free betting strategy that guarantees profit:",
    points: [
      "Identify odds discrepancies between bookmakers",
      "Place bets on all possible outcomes",
      "Secure profit regardless of event result"
    ],
    description: "Our platform automatically identifies these opportunities across major sportsbooks, showing you exactly how much to bet and where to place your wagers for guaranteed returns."
  },
  {
    intro: "We cover all major sports leagues and betting markets:",
    points: [
      "Major US leagues: NBA, NFL, MLB, NHL, WNBA",
      "College sports: NCAAB, NCAAF",
      "International sports and more"
    ],
    description: "We track odds from all major sportsbooks, including DraftKings, FanDuel, BetMGM, Caesars, ESPN Bet, Fanatics, and more, ensuring comprehensive coverage of betting markets."
  },
  {
    intro: "Finding +EV bets is easy with our platform:",
    points: [
      "Use our Positive EV calculator",
      "Sort by highest edge percentage",
      "Filter by sport, market, or book"
    ],
    description: "Our algorithms analyze thousands of betting lines to find statistically advantageous opportunities where the true probability is better than the implied odds, giving you a mathematical edge."
  },
  {
    intro: "For maximum effectiveness:",
    points: [
      "Yes, we recommend accounts with multiple sportsbooks",
      "More books = more opportunities",
      "Our referral section has sign-up bonus offers"
    ],
    description: "We help you get started with guides on which books to prioritize and how to maximize the value of sign-up bonuses, potentially adding hundreds or thousands to your initial bankroll."
  },
  {
    intro: "We offer multiple subscription options:",
    points: [
      "Free trial available",
      "Monthly and annual premium plans",
      "Full access to all tools and features"
    ],
    description: "Start with our 7-day free trial to experience the value firsthand. Premium memberships start at $29.99/month with significant discounts for annual subscribers. The ROI typically exceeds the cost within days."
  }
];

export default function FAQSection() {
  return (
    <>
      {/* FAQ Section */}
      <div className="relative mt-[-144px] flex flex-col items-center">
        <div className="flex flex-col items-center justify-center gap-[26px] md:flex-col md:px-5">
          <div className="relative w-full max-w-[1200px] flex-1 flex flex-col items-center px-4 md:px-0">
            <div className="flex flex-col items-center gap-[38px] lg:px-5">
              <Heading
                size="heading5xl"
                as="h1"
                className="text-center !font-manrope capitalize tracking-[-1.80px] text-white md:text-[40px] sm:text-[34px]"
              >
                Frequently Asked Questions
              </Heading>
              
              {/* Decorative Elements */}
              <img
                className="absolute w-[162px] h-[346px] top-[415px] right-[1200px]"
                alt="Eliments"
                src="/Images/HomePag-img/startQuest.png"
              />
              <img
                className="absolute w-[162px] h-[346px] top-[815px] left-[1200px] lg:left-0"
                alt="Eliments"
                src="/Images/HomePag-img/startQuest.png"
              />
              
              {/* Accordion Component */}
              <Accordion preExpanded={[0]} className="w-full flex flex-col gap-2.5">
                {accordionData.map((d, i) => (
                  <AccordionItem uuid={i} key={`ExpandableList${i}`}>
                    <div className="rounded-[20px] border border-solid border-white-a700_47 bg-blue_gray-900_01 p-3.5">
                      <div className="flex flex-col gap-[18px] rounded-[20px] bg-gray-900_02 p-7 md:p-4">
                        <AccordionItemHeading>
                          <AccordionItemButton className="flex items-center justify-between w-full">
                            <AccordionItemState>
                              {(props) => (
                                <>
                                  <div className="flex items-start gap-5 w-full">
                                    <div className="flex flex-col gap-4 w-full">
                                      <div className="flex items-center gap-2">
                                        <Heading
                                          size="headingxl"
                                          as="h4"
                                          className="!font-manrope tracking-[-1.04px] text-white"
                                        >
                                          {d.questionNumber}
                                        </Heading>
                                        <Heading
                                          size="headingxl"
                                          as="h4"
                                          className="!font-manrope tracking-[-1.04px] text-white"
                                        >
                                          {d.questionSeparator}
                                        </Heading>
                                      </div>
                                      <Heading
                                        size="headingxl"
                                        as="h4"
                                        className="!font-manrope tracking-[-1.04px] text-white"
                                      >
                                        {d.questionContent}
                                      </Heading>
                                    </div>
                                    {props?.expanded ? (
                                      <Img
                                        src="/Images/HomePag-img/Polygon 2.png"
                                        alt="Collapse Icon"
                                        className="h-[18px] w-[18px]"
                                      />
                                    ) : (
                                      <Img
                                        src="/Images/HomePag-img/Polygon 1.png"
                                        alt="Expand Icon"
                                        className="h-[18px] w-[18px]"
                                      />
                                    )}
                                  </div>
                                </>
                              )}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        
                        {/* Accordion Content */}
                        <AccordionItemPanel>
                          <div className="flex flex-col gap-5 md:mx-0">
                            <Heading size="textlg" as="p" className="!text-indigo-100">
                              {faqAnswers[i].intro}
                            </Heading>
                            
                            <div className="flex flex-col gap-[30px]">
                              <div className="pl-4">
                                <ul className="list-disc text-indigo-100">
                                  {faqAnswers[i].points.map((point, idx) => (
                                    <li key={idx} className="mb-2">
                                      <Heading size="textlg" as="p" className="!text-indigo-100">
                                        {point}
                                      </Heading>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                              
                              <Heading size="textlg" as="p" className="leading-8 !text-indigo-100">
                                {faqAnswers[i].description}
                              </Heading>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </div>
                    </div>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      
      {/* Decorative Elements at Bottom */}
      <div className="flex justify-end h-[350px]">
        <img
          src="./Images/HomePag-img/Clip.png"
          alt="Decorative Icon"
          className="h-[146px] w-[4%] object-contain md:w-[10%] mt-80"
        />
      </div>
      <Img
        src="./Images/HomePag-img/btc-2.png"
        alt="Decorative Icon"
        className="mb-2.5 h-[100px] w-[4%] object-contain md:w-[10%] md:h-auto"
      />
    </>
  );
}
